// singletonFetch.js
import axios from 'axios';

const { REACT_APP_API_ENDPOINT } = process.env;
const fetchURL = new URL(`${REACT_APP_API_ENDPOINT}/api/depositi/garages`);

class SingletonFetch {
    constructor() {
        if (!SingletonFetch.instance) {
            SingletonFetch.instance = this;
            this.data = null; // Memorizza i dati caricati
            this.isFetching = false;
        }
        return SingletonFetch.instance;
    }

    async fetchData() {
        if (this.data) {
            return this.data; // Restituisci i dati memorizzati se già presenti
        }

        if (this.isFetching) {
            return new Promise((resolve) => {
                const interval = setInterval(() => {
                    if (this.data) {
                        clearInterval(interval);
                        resolve(this.data);
                    }
                }, 100);
            });
        }

        this.isFetching = true; // Imposta il flag di fetching

        try {
            const response = await axios.get(fetchURL);
            this.data = response.data; // Memorizza i dati
            return this.data;
        } catch (error) {
            console.error('Errore durante il fetching dei dati:', error);
            throw error;
        } finally {
            this.isFetching = false; // Reset del flag di fetching
        }
    }
}

const singletonFetch = new SingletonFetch();
export default singletonFetch;
