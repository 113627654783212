import React, { useState, useContext } from 'react';
import { ThemeContext } from '../context/ThemeContext';

const ControlPanel = ({
  showOffices,
  setShowOffices,
  showGarages,
  setShowGarages,
  selectedCity,
  setSelectedCity,
  cities,
}) => {
  const { darkMode } = useContext(ThemeContext);
  const [isExpanded, setIsExpanded] = useState(false); // Stato per gestione apertura/chiusura

  const collapsedStyle = {
    position: 'absolute',
    top: '120px',
    left: '10px',
    width: '50px', // Larghezza ridotta
    height: '50px', // Altezza ridotta
    backgroundColor: darkMode ? '#333' : '#fff',
    color: darkMode ? '#fff' : '#000',
    borderRadius: '50%',
    boxShadow: '0 2px 6px rgba(0,0,0,0.3)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    zIndex: 1000,
  };

  const expandedStyle = {
    position: 'absolute',
    top: '120px',
    left: '10px',
    width: '300px',
    height: 'auto', // Adatta l'altezza in base al contenuto
    maxHeight: '80vh', // Limita l'altezza per evitare overflow
    backgroundColor: darkMode ? '#333' : '#fff',
    color: darkMode ? '#fff' : '#000',
    borderRadius: '8px',
    boxShadow: '0 2px 6px rgba(0,0,0,0.3)',
    padding: '10px',
    overflowY: 'auto', // Scroll per contenuti lunghi
    zIndex: 1000,
  };

  const labelStyle = {
    display: 'block',
    margin: '5px 0',
  };

  const selectStyle = {
    width: '100%',
    padding: '5px',
    borderRadius: '4px',
    backgroundColor: darkMode ? '#555' : '#fff',
    color: darkMode ? '#fff' : '#000',
    border: darkMode ? '1px solid #777' : '1px solid #ccc',
  };

  return isExpanded ? (
    <div style={expandedStyle}>
      <button
        onClick={() => setIsExpanded(false)}
        style={{
          position: 'absolute',
          top: '5px',
          right: '10px',
          background: 'none',
          border: 'none',
          color: 'inherit',
          cursor: 'pointer',
        }}
      >
        ❌
      </button>
      <h4>Filtri</h4>
      <div>
        <label style={labelStyle}>
          <input
            type="checkbox"
            checked={showOffices}
            onChange={(e) => setShowOffices(e.target.checked)}
          />
          Mostra Uffici
        </label>
      </div>
      <div>
        <label style={labelStyle}>
          <input
            type="checkbox"
            checked={showGarages}
            onChange={(e) => setShowGarages(e.target.checked)}
          />
          Mostra Depositi
        </label>
      </div>
      <div>
        <label style={labelStyle}>Città:</label>
        <select
          value={selectedCity}
          onChange={(e) => setSelectedCity(e.target.value)}
          style={selectStyle}
        >
          <option value="">Tutte</option>
          {cities.map((city, index) => (
            <option key={index} value={city}>
              {city}
            </option>
          ))}
        </select>
      </div>
    </div>
  ) : (
    <div style={collapsedStyle} onClick={() => setIsExpanded(true)}>
      <span style={{ fontSize: '20px', fontWeight: 'bold' }}>☰</span>
    </div>
  );
};

export default ControlPanel;
