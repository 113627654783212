import React, { useEffect, useState } from 'react';
import { Box, Typography, CircularProgress, Container, Button } from '@mui/material';
import {  styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import axios from 'axios';
import { getAffissioniRegions, getAffissioniCities } from './apiAdminAffissioni'; // Assicurati che il percorso sia corretto
import {
  Gauge,
  useGaugeState,
  gaugeClasses
} from '@mui/x-charts/Gauge';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import StoreIcon from '@mui/icons-material/Store';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Stack from '@mui/material/Stack';
const { REACT_APP_API_ENDPOINT } = process.env;

function GaugePointer() {
  const { valueAngle, outerRadius, cx, cy } = useGaugeState();

  if (valueAngle === null) {
    // No value to display
    return null;
  }

  const target = {
    x: cx + outerRadius * Math.sin(valueAngle),
    y: cy - outerRadius * Math.cos(valueAngle),
  };
  return (
    <g>
      <circle cx={cx} cy={cy} r={5} fill='#52b202' />
      <path
        d={`M ${cx} ${cy} L ${target.x} ${target.y}`}
        stroke="red"
        strokeWidth={3}
      />
    </g>
  );
}


const AdminManagedStatUffici = () => {
  const [campaignMenu, setCampaignMenu] = useState([]);
  const [campaignSelected, setCampaignSelected] = useState('');
  const [campaignData, setCampaignData] = useState([]);
  const [campaignOveralData, setCampaignOveralData] = useState({});
  const [loading, setLoading] = useState(false);

  const [affissioniRegions, setAffissioniRegions] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedCity, setSelectedCity] = useState('');

  const [notFound, setNotFound] = useState('');
  const [gaugeStatPartial, setGaugeStatPartial] = useState(null);

  const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));

useEffect(() => {
    const fetchCampaignProgress = async () => {
      try {
        const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/campaigns-affissioni-menu`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        console.log('Campaign:', response.data);
        setCampaignMenu(response.data);
      } catch (error) {
        console.error('Error fetching campaign progress:', error);
      }
    };
    
    // Usa l'ID della campagna che vuoi testare
    fetchCampaignProgress(); // Assicurati che questo ID esista nel database
  }, []);

    // Carica le regioni all'avvio
    useEffect(() => {
      const fetchRegions = async () => {
          try {
              const response = await getAffissioniRegions();
              console.log(response)
              setAffissioniRegions(response);
          } catch (error) {
              console.error('Error fetching affissioniRegions:', error);
          }
      };
  
      fetchRegions();
    }, []);
  
    // Popola le città in base alla regione selezionata
    useEffect(() => {
      const fetchCities = async () => {
        if (selectedRegion) {
          const response = await getAffissioniCities(selectedRegion);
          setCities(response);
        }
      };
  
      fetchCities();
    }, [selectedRegion]);


  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  function chartSetting(labels, media){

      return  {
    yAxis: [
      {
        label: `${labels}`,
      },
    ],
    width: 250,
    height: 300,
    sx: {[`.${axisClasses.left} .${axisClasses.label}`]: {transform: 'translate(-8px, 10)',},
    },
  };
  }
  const colors = [
    '#00cc00',
    '#FF8F33',
    '#ff33ff',
    '#72ccff'];

/*
GET GET /api/statistiche/uffici/overal/67069fca2e3c208af46a83f7?regione=TOSCANA&citta=AREZZO
/api/admin/stat_uffici/overall/
*/

const fetchCampaignPartialOveralProgress = async (campaignId) => {
      try {
        setLoading(true)
       // const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/campaigns/${campaignId}/overall-progress`, {
       // const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/statistiche/uffici/overal/${campaignId}`, {
       // const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/mystat/statistiche/uffici/overal/${campaignSelected}?regione=${selectedRegion}&citta=${selectedCity}`, {
        const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/admin/stat_uffici/overall/${campaignSelected}?regione=${selectedRegion}&citta=${selectedCity}`, {   
       headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        console.log('Campaign Progress:', response.data);
         setCampaignOveralData(response.data[0]);
      /*   const gaugeStat = (parseInt(response.data[0][0].totaleMQ) / parseInt(response.data[1][0].totaleMQ)) * 100;
        setGaugeStatPartial(gaugeStat); */
        setLoading(false)
      } catch (error) {
        console.error('Error fetching campaign progress:', error);
        setLoading(false)
      }
}


/*
api/depositi-managed?campaignId=${campaignId}
*/
// http://localhost:5000/api/mystat/stat/uffici/managed?campaignId=67069fca2e3c208af46a83f7&regione=TOSCANA&citta=AREZZO

const fetchCampaignProgress = async (campaignId) => {
      try {
       // const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/campaigns/${campaignId}/progress`, {
        const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/mystat/stat/uffici/managed?campaignId=${campaignSelected}&regione=${selectedRegion}&citta=${selectedCity}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        console.log('Campaign Progress:', response.data);
        
       setCampaignData(response.data);

      //  setCampaignData(prevState => response.data)
      } catch (error) {
        if (error.response.data.message) {
          setNotFound(error.response.data.message);
        }
        setCampaignData([]);
        setCampaignOveralData({});
        setGaugeStatPartial(null);
        setLoading(false)
        console.error('Error fetching campaign progress:', error);
      }
}

const handleCampaignId = (event) => {
  setCampaignSelected(event.target.value);
};

const handleChange = (event) => {
  console.log(campaignMenu);
  fetchCampaignProgress();
  fetchCampaignPartialOveralProgress()
  }; 

const MenuCampagne = () => {

  return (
    <>
    <Grid container spacing={1} style={{marginBottom: '25px'}}>

    <Grid size={12}>
    <Grid xs={12} sm={5} md={4}>
<FormControl fullWidth>
  <InputLabel id="demo-simple-label">Seleziona Campagna</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    label="Seleziona Campagna"
    id="demo-simple-select"
    value={campaignSelected}
    onChange={handleCampaignId}
  >
  {campaignMenu?.map((option, index) => {
          return (
            <MenuItem key={option._id} value={option._id}>
              {option.denominazione} - {option.anno}
            </MenuItem>
          )
})}

  </Select>
</FormControl>
</Grid>
</Grid>

<Grid size={12}>
<Grid xs={12} sm={5} md={4}>
  <FormControl fullWidth >
      <InputLabel id="regione">Seleziona Regione</InputLabel>
      <Select
      labelId="regione-label"
      label={"Seleziona Regione"}
      style={{padding:'4px 4px'}}
        value={selectedRegion}
        onChange={(e) => setSelectedRegion(e.target.value)}
      >
        {affissioniRegions.map((region) => (
          <MenuItem key={region} value={region}>
            {region}
          </MenuItem>
        ))}
        <MenuItem value={''} onChange={(e) => setSelectedRegion('')} style={{color:'red', fontWeight:'bolder'}}>NULL</MenuItem>
      </Select>
    </FormControl>
  </Grid>

</Grid>

<Grid size={12}>
<Grid xs={12} sm={5} md={4}>
  <FormControl fullWidth >
      <InputLabel id="citta">Seleziona Città</InputLabel>
      <Select
      labelId="citta-label"
      label={"Seleziona Città"}
      style={{padding:'4px 4px'}}
        value={selectedCity === -1 ? '' : selectedCity}
        onChange={(e) => setSelectedCity(e.target.value)}
        disabled={!selectedRegion} // Disabilita il selettore città finché la regione non è selezionata
      >
        {cities.map((city) => (
          <MenuItem key={city} value={city}>
            {city}
          </MenuItem>
        ))}
        <MenuItem value={''} onChange={(e) => setSelectedCity('')} style={{color:'red', fontWeight:'bolder'}}>NULL</MenuItem>
      </Select>
    </FormControl>
  </Grid>
</Grid>

<Button variant="outlined"
onClick={handleChange}>Filtra dati</Button>
</Grid>
</>
  )
}



  return (
    <Container >
    <Box sx={{ padding: '0 !important' }}>
    <Stack alignItems="center" direction="row" gap={2} sx={{ mb:4 }}>
      <StoreIcon />
      <Typography variant="body1">Admin Dashboard progresso per campagna Uffici </Typography>
    </Stack>
     
    {MenuCampagne()}
    {notFound}
    {campaignOveralData &&

      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} spacing={2} mb={8}>
        <Grid size={12}>
          <Item>
          <Grid container direction="row"
        alignItems="center"
        justifyContent="center"
      >
      {/*
{
    "totaleMQ": 2193.29,
    "totaleMQapplicati": 270.07,
    "totalOffices": 440,
    "completedOffices": 46,
    "completionRate": "10.45",
    "regione": "Tutti",
    "citta": "Tutte"
}
        
        */}

            <CardContent >
            <h2 id="battery_level_label" style={{color: 'orangered'}}>
              { campaignOveralData.completionRate !== undefined ? `Overall Progress: ${campaignOveralData.completionRate}%` : `Overall Progress: 0%`}
            </h2>
            <Gauge
             // width={200}
              height={200}
              startAngle={-110}
              endAngle={110}
              cornerRadius="0%"
            //  innerRadius="72%"
            //  outerRadius="100%"
              value={isNaN(campaignOveralData.completionRate) ? Number(0) : parseFloat(campaignOveralData.completionRate).toFixed(2)}
              aria-labelledby="battery_level_label"
             // style={{marginLeft: '17%'}}
           //  {...settings}

            sx={(theme) => ({
              [`& .${gaugeClasses.valueText}`]: {
                fontSize: 30,
              },
              [`& .${gaugeClasses.valueArc}`]: {
                fill: '#14ff00'
              },
              [`& .${gaugeClasses.referenceArc}`]: {
                fill: theme.palette.text.disabled,
              },
            })}
            >
          {/*
            {
        "totalRecords": 18,
        "totaleMQDaCoprire": 146.51,
        "totaleMQApplicati": 14.56,
        "ufficiCompletati": 1,
        "metriQuadratiMancanti": 131.95,
        "completionRate": 5.56
    }
            */}
              
              <GaugePointer />
            </Gauge>
            <Typography gutterBottom sx={{ color: 'orangered', fontSize: 14 }}>
            {campaignOveralData.totaleMQDaCoprire !== undefined ? `MQ Tot.: ${campaignOveralData.totaleMQDaCoprire} , MQ Applicati: ${campaignOveralData.totaleMQApplicati.toFixed(2)}, MQ da coprire ${campaignOveralData.metriQuadratiMancanti.toFixed(2)}`
          
          : `Città: nd, Regione: nd, MQ Applicati: nd`}
              
            </Typography>
            <Typography gutterBottom sx={{ color: '#32ce3a', fontSize: 14, fontWeight: 800 }}>
            {campaignOveralData !== undefined ? `Uffici: ${campaignOveralData.totalRecords}, Completati: ${(campaignOveralData.ufficiCompletati)}`
            : `Uffici: nd, Uffici coompletati: nd `}
            </Typography>
          </CardContent>

      </Grid>

          </Item>
        </Grid>
      </Grid>

      }



    </Box>
    <div style={{ width: '100%' }}  >
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

      {campaignData.map((progress, index) => (

        <Grid  key={index} size={{ xs: 12, md: 3 }}>
          <Item>

          <BarChart style={{marginLeft: '23px'}}
          //  dataset={progress}
          xAxis={[{ scaleType: 'band', data: ['completed', 'uffici', 'MQ',], colorMap: {
            type: 'ordinal',
            colors,
          },}]}
          //  xAxis={[{ scaleType: 'band', dataKey: "garage" }]}
          series={[{ data: [progress.campaignInfo.length, progress.campaignInfo.length, progress.ufficioDetails.MQ], label: `${100}% completato` }]}
          slotProps={{ legend: { hidden: false } }}
          barLabel="value"
          /*
            series={[
              { dataKey: progress.completed, label: 'completed', fill: '#82ca9d' },
              { dataKey: progress.in_progress, label: 'in progress', fill: '#8884d8' },
              { dataKey: progress.total, label: 'tot. mezzi', fill: ' #DAF7A6' }
            ]}
*/
           {...chartSetting( progress.ufficioDetails.denominazione, progress.media)}
          />
                
          </Item>
        </Grid>

              ))}


      </Grid>
    </div>
</Container >
  );
};

export default AdminManagedStatUffici;
