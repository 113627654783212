import React, { useState, useEffect, useContext } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Paper,
  Drawer,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useNavigate, Outlet } from 'react-router-dom';
import { jwtDecode } from "jwt-decode"; // Assicurati di importare correttamente
import { ThemeContext } from '../context/ThemeContext';
import { Brightness4 as DarkModeIcon, Brightness7 as LightModeIcon } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import DrawerList from './DrawerList'; // Usa il DrawerList importato
import BasicMenu from './Menu/BasicMenu';
import GestioneMenu from './Menu/GestioneMenu';
import UserMenu from './Menu/UserMenu';
import UserMenu2 from './Menu/UserMenu2';
import AccountMenu from './Menu/AccountMenu';
import Logos from '../Risors1@2x.png';
import PlaceIcon from '@mui/icons-material/Place';

const Home = ({ setIsAuthenticated }) => {
  const [role, setRole] = useState(null);
  const [userName, setUserName] = useState('');
  const { darkMode, toggleDarkMode } = useContext(ThemeContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const Item = ({ children, sx = {} }) => (
    <Paper
      sx={{
        backgroundColor: darkMode ? '#1A2027' : '#fff',
        color: darkMode ? 'yellow' : 'black',
        textAlign: 'center',
        padding: 2,
        ...sx,
      }}
    >
      {children}
    </Paper>
  );

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setRole(decoded.role);
        setUserName(decoded.username);
      } catch (error) {
        console.error('Errore nella decodifica del token:', error);
        handleLogout();
      }
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    navigate('/login');
  };

  const toggleDrawer = (open) => () => setDrawerOpen(open);

  const renderWelcomeMessage = () => {
    if (role && userName) {
      return (
        <Grid container  spacing={2}>
          <Grid  size={12}>
            <Item>Salve {userName}, Ruolo: {role.charAt(0).toUpperCase() + role.slice(1)}</Item>
          </Grid>
        </Grid>
      );
    }
    return null;
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
        <Typography variant='h6' component ='div' sx={{flexGrow:1, display:{xs: 'none', md:'flex'}}}><img src={Logos} alt="Logo" style={{width: '14%'}}/></Typography>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          <Box >
    <IconButton size='large' color='inherit' edge='start' onClick={() => navigate('/home/mappa')}>
    <PlaceIcon />
    </IconButton>
    </Box>
            {/* Menu per Manager */}
            {role === 'manager' && (
              <>
                <UserMenu2 />
                <BasicMenu />
                <GestioneMenu />
   
                
              </>
            )}

            {/* Menu per Admin */}
            {role === 'admin' && <BasicMenu />}

            {/* Menu per User */}
            {role === 'user' && (
              <>
                <UserMenu2 />
                
              </>
            )}

            {/* Menu Tools visibile a tutti */}
            <IconButton onClick={toggleDarkMode} color="inherit">
              {darkMode ? <DarkModeIcon /> : <LightModeIcon />}
            </IconButton>
            <AccountMenu />
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton size="large" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer open={drawerOpen} onClose={toggleDrawer(false)}>

              <DrawerList
                role={role}
                darkMode={darkMode}
                toggleDarkMode={toggleDarkMode}
                handleLogout={handleLogout}
                onCloseDrawer={toggleDrawer(false)} // Passa la funzione per chiudere il Drawer
              />

            </Drawer>
          </Box>
        </Toolbar>
      </AppBar>
      {renderWelcomeMessage()}
      <Box>
        <Outlet />
      </Box>
    </>
  );
};

export default Home;
