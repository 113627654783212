import { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext); // Recupera il contesto autenticazione
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  // Funzione di login che salva il token
  const login = (token) => {
    localStorage.setItem('token', token);
    setIsAuthenticated(true);
  };

  // Funzione di logout che rimuove il token
  const logout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
  };

  // Controlla se il token è presente e valido
  const checkTokenValidity = (token) => {
    try {
      const decodedToken = jwtDecode(token); // Decodifica il token JWT
      const currentTime = Date.now() / 1000; // Ottieni il tempo attuale in secondi
      return decodedToken.exp > currentTime; // Verifica se il token è ancora valido
    } catch (error) {
      console.error('Errore durante la decodifica del token:', error);
      return false; // Se il token non è valido, restituisci false
    }
  };

  // Controlla se c'è un token al caricamento iniziale dell'app
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token && checkTokenValidity(token)) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
      localStorage.removeItem('token'); // Rimuovi il token se non è valido
    }
    setLoading(false);
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};
