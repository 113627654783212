import React, { useState } from 'react';
import Resizer from 'react-image-file-resizer';
import { Button, Typography, IconButton, Alert } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { MuiFileInput } from 'mui-file-input'
const { REACT_APP_API_ENDPOINT } = process.env;

/*
          campaign={selectedCampaign} 
          region={selectedRegion} 
          city={selectedCity} 
          ufficio={selectedUfficio} 
          ufficioId={selectedUfficioId}
*/

const ImageUploadAffissioni = ({campaign, region, city, ufficio, ufficioId, note, onSuccess }) => {
  const [images, setImages] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const handleFileChange = (e) => {
   // const files = e.target.files;
    const files = e;
    const resizedImages = [];

    Array.from(files).forEach((file) => {
      const fileType = file.type;
      const fileName = file.name;

      Resizer.imageFileResizer(
        file,
       1400, // max width
       1400, // max height
        fileType.includes('png') ? 'PNG' : 'JPEG', // Use the original file format
        70, // quality
        0, // rotation
        (uri) => {
          // Create a new File object from the blob
          const resizedFile = new File([uri], fileName, { type: fileType });
          resizedImages.push(resizedFile);

          // Ensure all images are resized before setting state
          if (resizedImages.length === files.length) {
            setImages(resizedImages); // Set resized images
          }
        },
        'blob' // Output type
      );
    });

    setSuccess(false);
    setError('');
  };

  const handleRemoveImage = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
  };

  const handleUpload = async (event) => {
    event.preventDefault();

    if (!campaign || !region || !city || !ufficio || images.length === 0) {
      alert('Per favore, completa tutti i campi e carica almeno un\'immagine.');
      return;
    }

    const formData = new FormData();
   // formData.append('campaignId', campaign);
    formData.append('tipologia', 'affissioni');
    formData.append('note', note);
    formData.append('ufficio_postale', JSON.stringify(ufficioId));
    formData.append('deposito', '');
    formData.append('anno', 'anno');
    formData.append('status', 'pending');
    formData.append('validatedBy', null);
    formData.append('approvedBy', null);
    /*
    formData.append('region', region);
    formData.append('city', city);
    formData.append('office', ufficio);
    formData.append('officeId', ufficioId._id);
    formData.append('MTb', ufficioId.MTb);
    formData.append('MTh', ufficioId.MTh);
*/
    images.forEach((image) => {
      formData.append('images', image);
    });

    setUploading(true);
    setProgress(0);

    try {
      await axios.post(`${REACT_APP_API_ENDPOINT}/api/campaigns/${campaign}/upload`, 
        formData, 
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const total = progressEvent.total;
            const current = progressEvent.loaded;
            const percentage = Math.floor((current / total) * 100);
            setProgress(percentage);
          }
        }
      );

      setSuccess(true);
      setImages([]);
      setProgress(0);
  
      // Mostra il successo e poi chiama la funzione di reset dopo 3 secondi
      setTimeout(() => {
        if (onSuccess) onSuccess();
      }, 3000);
  
    } catch (error) {
      console.error('Errore durante il caricamento delle immagini:', error);
      setError('Errore durante il caricamento. Sessione scaduta o errore imprevisto.');
      if (error.response?.status === 401) {
        navigate('/login');
      }
    } finally {
      setUploading(false);
    }
  };

  return (
    <div>
      <Typography variant="h6">Carica le Immagini per la Campagna</Typography>

      {images.length > 0 && (
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          {images.map((image, index) => (
            <Grid item key={index}>
              <div style={{ position: 'relative' }}>
                <img
                  src={URL.createObjectURL(image)}
                  alt={`Preview ${index}`}
                  style={{ width: 100, height: 100, objectFit: 'cover' }}
                />
                <IconButton
                  size="small"
                  color="error"
                  onClick={() => handleRemoveImage(index)}
                  style={{ position: 'absolute', top: 0, right: 0 }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </Grid>
          ))}
        </Grid>
      )}

      <MuiFileInput 
      multiple
      value={images}
      placeholder="Clicca qui per inserire Immagini"
      InputProps={{
        inputProps: {
          accept: 'image/*'
        },
        startAdornment: <AttachFileIcon />
      }}
      onChange={handleFileChange}

      clearIconButtonProps={{
      title: "Remove",
      children: <CloseIcon fontSize="small" />
    }}
      />
      {/*
      <input
        type="file"
        multiple
        accept="image/*"
        onChange={handleFileChange}
        style={{ marginTop: '20px' }}
      />
 */}

      <Grid size={12}>
      <Button
        variant="contained"
        onClick={handleUpload}
        disabled={uploading}
        size="small"
        sx={{ marginTop: '20px' }}
      >
        {uploading ? `Caricamento... ${progress}%` : 'Carica Immagini'}
      </Button>
      </Grid>
      {success && (
        <Alert severity="success" sx={{ marginTop: 2 }}>
          Caricamento avvenuto con successo!
        </Alert>
      )}

      {error && (
        <Alert severity="error" sx={{ marginTop: 2 }}>
          {error}
        </Alert>
      )}
    </div>
  );
};

export default ImageUploadAffissioni;
