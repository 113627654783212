import React, { useState, useEffect } from 'react';
import { TextField, Button, Select, MenuItem, Box,  Card, CardContent, } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import createAuthInterceptor from '../utils/authInterceptor'
import { Snackbar, Alert, Slide } from "@mui/material";
import { useSnackbar } from 'notistack';
const { REACT_APP_API_ENDPOINT } = process.env;

// Funzione per animare la transizione
const TransitionDown = (props) => {
  return <Slide {...props} direction="down" />;
};


const CarSearchAndUpdate = () => {

  const { enqueueSnackbar } = useSnackbar(); // Ottieni enqueueSnackbar dal contesto

  const [targa, setTarga] = useState('');

  const navigate = useNavigate();
  const axiosInstance = createAuthInterceptor(navigate);

  const [campagne, setCampagne] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState('');
  const [stato, setStato] = useState('');
  const [autoInfo, setAutoInfo] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  // Carica le campagne disponibili
  useEffect(() => {
    axios.get(`${REACT_APP_API_ENDPOINT}/api/campaigns`).then((res) => {
      setCampagne(res.data);
    });
  }, []);

  const handleSearch = async () => {
    try {
      const res = await axios.get(`${REACT_APP_API_ENDPOINT}/api/cars/${targa}`);
      console.log(res);
      setAutoInfo(res.data);
      setErrorMessage(null); // Reset messaggio errore
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setErrorMessage("Auto non trovata. Controlla la targa e riprova.");
      } else {
        setErrorMessage("Si è verificato un errore. Riprova più tardi.");
      }
    }
  };

/*   const handleSearchOld = async () => {
    const res = await axios.get(`${REACT_APP_API_ENDPOINT}/api/cars/${targa}`);
    console.log(res)
    setAutoInfo(res.data);
  }; */

  /* const handleUpdate = async () => {
    if (!selectedCampaign || !autoInfo) return;
  
    try {
      await axiosInstance.post(
        `${REACT_APP_API_ENDPOINT}/api/auto-campagna/aggiorna`,
        {
          campaignId: selectedCampaign,
          autoId: autoInfo._id,
          garageId: autoInfo.garageId,
          stato,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
  
      alert('Stato aggiornato con successo');
    } catch (error) {
      console.error('Errore durante l\'aggiornamento dello stato:', error);
      alert('Errore durante l\'aggiornamento dello stato');
    }
  }; */


const handleUpdate = async () => {
  
  if (!selectedCampaign || !autoInfo) {
    enqueueSnackbar('Seleziona una campagna e un\'auto per continuare.', { variant: 'warning' });
    return;
  }

  try {
    const response = await axiosInstance.post(
      `${REACT_APP_API_ENDPOINT}/api/auto-campagna/aggiorna`,
      {
        campaignId: selectedCampaign,
        autoId: autoInfo._id,
        garageId: autoInfo.garageId,
        stato,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );

    if (response.data.alreadyCompleted) {
      enqueueSnackbar('Auto già completata. Nessuna modifica necessaria.', { variant: 'info' });
    } else {
      enqueueSnackbar('Stato aggiornato con successo.', { variant: 'success' });
    }
  } catch (error) {
    console.error('Errore durante l\'aggiornamento dello stato:', error);
    enqueueSnackbar('Errore durante l\'aggiornamento dello stato. Riprova più tardi.', { variant: 'error' });
  }
};


  return (
    <>
          {/* Snackbar per mostrare errori */}
          <Snackbar
          open={!!errorMessage}
          autoHideDuration={6000}
          onClose={() => setErrorMessage(null)}
          TransitionComponent={TransitionDown} // Aggiunta della transizione
          anchorOrigin={{ vertical: "top", horizontal: "center" }} // Posizione
        >
          <Alert
            onClose={() => setErrorMessage(null)}
            severity="error"
            sx={{ width: "100%" }}
          >
            {errorMessage}
          </Alert>
        </Snackbar>
    <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    }}
  >
  <Card variant="outlined" sx={{ maxWidth: 500, padding: 2 }}>
  <CardContent>
      <TextField
        label="Inserisci targa"
        value={targa}
        onChange={(e) => setTarga(e.target.value.toUpperCase())}
      />
      <Button onClick={handleSearch}>Cerca Auto</Button>

      {autoInfo && (
        <>
          <div>
          <Grid container spacing={2}>

            <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            {`${autoInfo.marca} - ${autoInfo.modello} - ${autoInfo.targa}`}
            </Typography >

          </Grid>

          <FormControl sx={{ m: 1, minWidth: 180 }}>
          <InputLabel id="demo-simple-select-autowidth-label">Campagna</InputLabel>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={selectedCampaign}
            onChange={(e) => setSelectedCampaign(e.target.value)}
            autoWidth
            label="Campagna"
            required
          >
          {campagne.map((campagna) => (
            <MenuItem key={campagna._id} value={campagna._id}>
              {campagna.denominazione} - {campagna.anno}
            </MenuItem>
          ))}
          </Select>
        </FormControl>
      
          </div>

          <FormControl sx={{ m: 1, minWidth: 180 }}>
          <InputLabel id="demo-simple-select-autowidth-label">Stato</InputLabel>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={stato}
            onChange={(e) => setStato(e.target.value)}
            autoWidth
            label="Campagna"
          >
          <MenuItem value="in_progress">In Progress</MenuItem>
          <MenuItem value="completed">Completed</MenuItem>
          </Select>
        </FormControl>


          <Button onClick={handleUpdate}>Aggiorna Stato</Button>
        </>
      )}

  </CardContent>
  </Card>
    </Box>
   


      {/* Bottone o UI per la ricerca */}
      <button onClick={handleSearch}>Cerca Auto</button>
    </>
  );
};

export default CarSearchAndUpdate;
