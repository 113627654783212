import React, { useState } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Container,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import Alert from '@mui/material/Alert';

const RegistrationNewForm = ({ onClose, onUserCreated }) => {
  const [formData, setFormData] = useState({
    nome: '',
    cognome: '',
    email: '',
    password: '',
    username: '',
    role: 'user', // Default role
    active: false, // Checkbox value
  });

  const [success, setSuccess] = useState(false); // Stato per il successo
  const [error, setError] = useState(''); // Stato per l'errore

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Invia i dati al backend
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/users/register`,
        formData
      );
      console.log(response.data);

      // Chiama la callback per aggiornare la tabella
      onUserCreated();

      // Mostra successo e resetta il form
      setSuccess(true);
      setFormData({
        nome: '',
        cognome: '',
        email: '',
        password: '',
        username: '',
        role: 'user', // Reset role
        active: false, // Reset checkbox
      });

      // Chiudi il form dopo il salvataggio
      onClose();
    } catch (err) {
      console.error(err);
      setError('Errore durante la registrazione');
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" gutterBottom>
        Crea un nuovo utente
      </Typography>

      {success && <Alert severity="success">Registrazione avvenuta con successo!</Alert>}
      {error && <Alert severity="error">{error}</Alert>}

      <form onSubmit={handleSubmit}>
        <TextField
          label="Nome"
          name="nome"
          value={formData.nome}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Cognome"
          name="cognome"
          value={formData.cognome}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
          type="email"
        />
        <TextField
          label="Username"
          name="username"
          value={formData.username}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
          type="password"
        />
        <FormControl fullWidth margin="normal" required>
          <InputLabel id="role-label">Ruolo</InputLabel>
          <Select
            labelId="role"
            name="role"
            value={formData.role}
            onChange={handleChange}
          >
            <MenuItem value="user">User</MenuItem>
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="manager">Manager</MenuItem>
          </Select>
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              name="active"
              checked={formData.active}
              onChange={handleChange}
              color="primary"
            />
          }
          label="Attivo"
        />

        <Button type="submit" variant="contained" color="primary" fullWidth>
          Crea
        </Button>
      </form>
    </Container>
  );
};

export default RegistrationNewForm;
