import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';

import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import LogoutIcon from '@mui/icons-material/Logout';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import RepartitionIcon from '@mui/icons-material/Repartition';
import NoCrashIcon from '@mui/icons-material/NoCrash';
import StorefrontIcon from '@mui/icons-material/Storefront';
import GarageIcon from '@mui/icons-material/Garage';


export default function UserMenu2() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
     setAnchorEl(null);
  };

  const handleMenuItemClick = (event, index) => {
    //  setSelectedIndex(index);
      if (index === 9) {
          navigate(`/home/campain`)
      }
      if (index === 10) {
          navigate('/home/campain-affissioni');
      }
      if (index === 11) {
        navigate('/home/automobile');
      }
      if (index === 12) {
      navigate('/home/uffici');
      }
      if (index === 13) {
        navigate('/home/images');
    }
    if (index === 14) {
        localStorage.removeItem('token');
        // Reindirizza l'utente alla pagina di login
        navigate('/login');
    } 

      setAnchorEl(null);
    };

  const navigate = useNavigate();

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{color: 'inherit'}}
      >
      Installatore
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >

      <MenuItem onClick={(event) => handleMenuItemClick(event, 9)}>
      <ListItemIcon>
      <GarageIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Depositi</ListItemText>
      
      </MenuItem>

       <MenuItem onClick={(event) => handleMenuItemClick(event, 10)}>
       <ListItemIcon>
       <StorefrontIcon fontSize="small" />
          
         </ListItemIcon>
         <ListItemText>Affissioni</ListItemText>

       </MenuItem>

       
       <MenuItem onClick={(event) => handleMenuItemClick(event, 11)}>
       <ListItemIcon>
           <NoCrashIcon  fontSize="small" />
         </ListItemIcon>
         <ListItemText>Valida Mezzi</ListItemText>
       </MenuItem>


       <MenuItem onClick={(event) => handleMenuItemClick(event, 12)}>
       <ListItemIcon>
       <RepartitionIcon fontSize="small" />
         </ListItemIcon>
         <ListItemText>Valida Uffici</ListItemText>
       
       </MenuItem>

       <MenuItem onClick={(event) => handleMenuItemClick(event, 13)}>
       <ListItemIcon>
       <PhotoCameraIcon fontSize="small" />
         </ListItemIcon>
         <ListItemText>Foto</ListItemText>
       
       </MenuItem>

       <MenuItem onClick={(event) => handleMenuItemClick(event, 14)}>
       <ListItemIcon>
       <LogoutIcon fontSize="small" />
         </ListItemIcon>
         <ListItemText>Logout</ListItemText>
       
       </MenuItem>

      </Menu>
    </>
  );
}
