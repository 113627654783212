import React from 'react';
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  DarkMode as DarkModeIcon,
  LightMode as LightModeIcon,
  Place as PlaceIcon,
  BarChart as BarChartIcon,
  Business as BusinessIcon,
  Garage as GarageIcon,
  CarRental as CarRentalIcon,
  GroupAdd as GroupAddIcon,
  PhotoCamera as PhotoCameraIcon,
  MapsHomeWork as MapsHomeWorkIcon,
} from '@mui/icons-material';
import LogoutIcon from '@mui/icons-material/Logout';
import RepartitionIcon from '@mui/icons-material/Repartition';
import NoCrashIcon from '@mui/icons-material/NoCrash';
import StorefrontIcon from '@mui/icons-material/Storefront';



import ToysIcon from '@mui/icons-material/Toys';
import CampaignIcon from '@mui/icons-material/Campaign';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

const DrawerList = ({ role, darkMode, toggleDarkMode, handleLogout, onCloseDrawer }) => {
  const navigate = useNavigate();
        
  // Configurazione dinamica del menu
  const menuConfig = {
    manager: [
      { index: 0, icon: <PlaceIcon />, text: 'Mappa', path: '/home/mappa' },
      { index: 1, icon: <BarChartIcon />, text: 'Dash Depositi', path: '/home/manage/dash-depositi' },
      { index: 2, icon: <BarChartIcon />, text: 'Dash Affissioni', path: '/home/manage/dash-uffici' },
      { index: 3, icon: <BusinessIcon />, text: 'Uffici', path: '/home/uffici-all' },
      { index: 4, icon: <GarageIcon />, text: 'Garages', path: '/home/garage' },
      { index: 5, icon: <CarRentalIcon />, text: 'Flotta', path: '/home/flotta' },
      { index: 6, icon: <GroupAddIcon />, text: 'Utenti', path: '/home/manage/users' },
      { index: 7, icon: <PersonAddIcon />, text: '+ Utente', path: '/home/admin/registation' },
      { index: 8, icon: <CampaignIcon />, text: '+ Campagna', path: '/home/manage/createcampaign' },

      { index: 9, icon: <PhotoCameraIcon />, text: 'Immagini', path: '/home/admin/all-images' },
      { index: 10, icon: <ToysIcon />, text: 'Manage Depositi', path: '/home/manage/depositi' },
      { index: 11, icon: <MapsHomeWorkIcon />, text: 'Manage Affissioni', path: '/home/manage/uffici' },
    ],
    admin: [
      { index: 0, icon: <PlaceIcon />, text: 'Mappa', path: '/home/mappa' },
      { index: 1, icon: <BarChartIcon />, text: 'Dash Depositi', path: '/home/admin/dash-dep' },
      { index: 2, icon: <BusinessIcon />, text: 'Panel Depositi', path: '/home/admin/panel-depositi' },
    ],
    user: [
      { index: 9, icon: <GarageIcon />, text: 'Depositi', path: '/home/campain' },
      { index: 10, icon: <StorefrontIcon />, text: 'Affissioni', path: '/home/campain-affissioni' },
      { index: 11, icon: <NoCrashIcon />, text: 'Valida Mezzi', path: '/home/automobile' },
      { index: 12, icon: <RepartitionIcon />, text: 'Valida Uffici', path: '/home/uffici' },
      { index: 13, icon: <PhotoCameraIcon />, text: 'Foto', path: '/home/images' },
    ],
  };
  const handleLogoutApp = (path) => {
    localStorage.removeItem('token');
        // Reindirizza l'utente alla pagina di login
        navigate('/login');
  };
  // Menu tools visibile a tutti
  const toolsMenu = [
    {
      text: darkMode ? 'Light Mode' : 'Dark Mode',
      icon: darkMode ? <LightModeIcon /> : <DarkModeIcon />,
      action: toggleDarkMode,
    },
    { text: 'Logout', icon: <LogoutIcon />, action: handleLogoutApp },
  ];

  const handleMenuItemClick = (path) => {
    navigate(path);
    onCloseDrawer();
  };



  return (
    <Box sx={{ width: 250 }} role="presentation">
      <List>
        {/* Menu Dinamico in base al ruolo */}
        {(menuConfig[role] || []).map(({ index, icon, text, path }) => (
          <MenuItem key={index} onClick={() => handleMenuItemClick(path)}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText>{text}</ListItemText>
          </MenuItem>
        ))}

        <Divider />

        {/* Menu Tools */}
        {toolsMenu.map(({ text, icon, action }, i) => (
          <ListItem key={`tool-${i}`} disablePadding>
            <ListItemButton onClick={action}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default DrawerList;
