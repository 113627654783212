import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TextField, Button, Typography, Box, Card, CardContent, Stack } from '@mui/material';
import LockResetIcon from '@mui/icons-material/LockReset';
const { REACT_APP_API_ENDPOINT } = process.env;

const ForgotPasswordStep3 = () => {
  const location = useLocation();
  const otp = location.state?.otp; // Recupera l'OTP passato dallo stato
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSubmit = async () => {
    if (password !== confirmPassword) {
      alert('Le password non corrispondono');
      return;
    }

    const response = await fetch(`${REACT_APP_API_ENDPOINT}/api/auth/reset-password`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ password, otp })
    });

    if (response.ok) {
      alert('Password modificata con successo');
      window.location.href = '/login'; // Torna al login
    } else {
      alert('Errore durante la modifica della password');
    }
  };

  return (
  <>
  <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f5f5f5'
      }}
    >
      <Card variant="outlined" sx={{ maxWidth: 500, padding: 2 }}>
        <CardContent>
          <Stack direction="row" spacing={4} sx={{ alignItems: 'flex-end', justifyContent: 'center' }}>
            <LockResetIcon fontSize="large" />
          </Stack>
          <Typography variant="h5" gutterBottom>Nuova Password</Typography>
      <TextField
        fullWidth
        label="Nuova Password"
        type="password"
        margin="normal"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <TextField
        fullWidth
        label="Conferma Password"
        type="password"
        margin="normal"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />
      <Button variant="contained" fullWidth onClick={handleSubmit} sx={{ mt: 2 }}>Modifica</Button>
        </CardContent>
      </Card>
    </Box>
</>

  );
};

export default ForgotPasswordStep3;
