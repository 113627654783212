import * as React from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { jwtDecode } from "jwt-decode"; // Assicurati di importare correttamente
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
const { REACT_APP_API_ENDPOINT } = process.env;

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [userInfo, setUserInfo] = React.useState({})

  const navigate = useNavigate();


    React.useEffect(() => {

      const fetchUser = async (userId) => {
        try {
          const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/users/getOne?id=${userId}`);
          console.log('Campaign:', response.data);
          setUserInfo(response.data);

        } catch (error) {
          console.error('Error fetching campaign progress:', error);
        }
      };
      
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const decoded = jwtDecode(token);
          fetchUser(decoded.userId)
        
      } catch (error) {
          console.error('Errore nella decodifica del token:', error);
         // handleLogout();
        }
      }
    }, []);


 /*    React.useEffect(() => {
      fetch(`${REACT_APP_API_ENDPOINT}/api/users/getOne?id=${userId}`)
        .then((response) => response.json())
        .then((data) => {
          setUserInfo(data);
          console.log(data);
        })
        .catch((error) => console.log(error));
    }, [userId]); */





  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event, index) => {

    if (index === 1) {
        navigate(`/home/userinfo`)
    }
    if (index === 2) {
      //  navigate('/home/admin/');
    }
    if (index === 3) {
    //  navigate('/home/admin/registation');
    }
    if (index === 4) {
     // navigate('/home/admin/panel-affissioni');
    } 
    if (index === 5) {
        localStorage.removeItem('token');
        // Reindirizza l'utente alla pagina di login
        navigate('/login');
    } 
    setAnchorEl(null);
  };


function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

  const stringAvatar = (name) => {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }
// per renderizzare nome e cognome
  const isEmptyObject = (userInfo) => Object.keys(userInfo).length === 0;

  return (
    <React.Fragment>
    {console.log(userInfo)}
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
          {isEmptyObject(userInfo) ? 'Object is empty' :  <Avatar {...stringAvatar(userInfo.nome+' '+userInfo.cognome)} />}

          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >

        <MenuItem 
        onClick={(event) => handleMenuItemClick(event, 1)}
         >
        <Avatar /> Profilo
        </MenuItem>
       
        <Divider />

        <MenuItem onClick={(event) => handleMenuItemClick(event, 4)}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Preferenze
        </MenuItem>

        <MenuItem onClick={(event) => handleMenuItemClick(event, 5)}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Quit
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
