
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Select, MenuItem, Button, FormControl, InputLabel, Container } from '@mui/material';
import { Typography, Card, CardMedia, CardHeader, CardContent } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { red } from '@mui/material/colors';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useNavigate } from 'react-router-dom';
//import axios from 'axios';

import Grid from '@mui/material/Grid2';

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import createAuthInterceptor from '../../utils/authInterceptor';
const { REACT_APP_API_ENDPOINT } = process.env;


const AdminPanelAffissioni = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [regioni, setRegioni] = useState([]);
  const [citta, setCitta] = useState([]);
  const [ufficio, setUfficio] = useState([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState('');
  const [selectedAnno, setSelectedAnno] = useState('');
  const [selectedRegione, setSelectedRegione] = useState('');
  const [selectedCitta, setSelectedCitta] = useState('');
  const [selectedUfficio, setSelectedUfficio] = useState('');
  const [images, setImages] = useState([]);
  //const [open, setOpen] = useState(false);

  const [noRecordFound, setNoRecordFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const axiosInstance = createAuthInterceptor(navigate);

  // ligthbox
const [index, setIndex] = useState(-1);
const currentImage = images[index];
const nextIndex = (index + 1) % images.length;
const nextImage = images[nextIndex] || currentImage;
const prevIndex = (index + images.length - 1) % images.length;
const prevImage = images[prevIndex] || currentImage;

const handleClick = (index, item) => setIndex(index);
const handleClose = () => setIndex(-1);
const handleMovePrev = () => setIndex(prevIndex);
const handleMoveNext = () => setIndex(nextIndex);

  // Per recuperare le campagne disponibili dal backend
  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const response = await axiosInstance.get(`${REACT_APP_API_ENDPOINT}/api/campaigns-affissioni`);
        setCampaigns(response.data);
      } catch (error) {
        console.error('Errore nel recupero delle campagne:', error);
      }
    };

    fetchCampaigns();
  }, []);


  

  // Per recuperare le regioni disponibili dal backend
  useEffect(() => {
    const fetchRegioni = async () => {
      try {
        const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/affissioni/regions`);
        setRegioni(response.data);
      } catch (error) {
        console.error('Errore nel recupero delle regioni:', error);
      }
    };

    fetchRegioni();
  }, []);

  // Quando una regione è selezionata, recupera le città corrispondenti
  useEffect(() => {
    if (selectedRegione) {
      const fetchCitta = async () => {
        try {
          const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/affissioni/citta?region=${selectedRegione}`);
          setCitta(response.data);
        } catch (error) {
          console.error('Errore nel recupero delle città:', error);
        }
      };

      fetchCitta();
    }
  }, [selectedRegione]);

  // Quando una città è selezionata, recupera i ufficio corrispondenti
  useEffect(() => {
    if (selectedCitta) {
      const fetchUffici = async () => {
        try {
          const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/affissioni/uffici?citta=${selectedCitta}`);
          setUfficio(response.data);
        } catch (error) {
          console.error('Errore nel recupero dei uffici:', error);
        }
      };
      
      fetchUffici();
    }
  }, [selectedCitta]);

  const handleFilter = async () => {
    try {
      // Invio di una richiesta con i parametri di filtro
      const response = await axiosInstance.get(`${REACT_APP_API_ENDPOINT}/api/admin/affissioni/campaign/images`, {
        params: {
          campaignId: selectedCampaigns,
          regione: selectedRegione,
          citta: selectedCitta,
          denominazione: selectedUfficio,
        },
      });

      console.log('Immagini filtrate:', response.data);
      // Gestione delle immagini filtrate
      setImages(response.data);
      if(response.data.length > 0) {
        setNoRecordFound(false);
      } else {
        setNoRecordFound(true);
      }
    } catch (error) {
      console.error('Errore nel recupero delle immagini:', error);
    }
  };
  // ... altro codice

  const handleReset = () => {
   
   setSelectedRegione('');
   setSelectedCitta('');
   setSelectedUfficio('');
   console.log("resetto")
   setLoading(false);
}
 

  return (
    <Container >
    <div style={{ marginBottom: '25px' }}>
      <h2>Admin Affissioni - Filtra per Campagna uffici</h2>
      </div>
      <Stack direction="row" spacing={1} sx={{mb: 2}}>
      {loading ? 
        <CircularProgress color="inherit" />
        : <IconButton aria-label="delete" onClick={()=> { setLoading(true); handleReset();} }>
        <AutorenewIcon />
      </IconButton> }
      <Box sx={{ '& > button': { m: 1 } }}>
      {loading ? 
        <LoadingButton
          size="small"
          
          loading={loading}
          variant="outlined"
          disabled
        >
          Disabled
        </LoadingButton>
        : null }
        </Box>
    </Stack>
      <Grid container spacing={1} style={{marginBottom: '25px'}}>


        <Grid size={4}>
          <Grid xs={12} sm={5} md={4}>
          <FormControl  fullWidth >
            <InputLabel id="select-campaign-label">Seleziona Campagna</InputLabel>
            <Select
            style={{padding:'4px 4px'}}
            value={selectedCampaigns}
            onChange={(e) => setSelectedCampaigns(e.target.value)}
            labelId="test-select-label"
            label={"Seleziona Campagna"}
            >
              {campaigns.map((campain) => (
                <MenuItem key={campain._id} value={campain._id}>
                  {campain.denominazione} -  {campain.anno}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          </Grid>
        </Grid>

        <Grid size={4}>
        <Grid xs={12} sm={5} md={4}>
          <FormControl fullWidth >
              <InputLabel id="select-region-label">Seleziona Regione</InputLabel>
              <Select
               style={{padding:'4px 4px'}}
                value={selectedRegione}
                onChange={(e) => setSelectedRegione(e.target.value)}
                labelId="regio-select-label"
                label={"Seleziona Regione"}
              >
                {regioni.map((regione) => (
                  <MenuItem key={regione} value={regione}>
                    {regione}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

        </Grid>

        <Grid size={4}>
        <Grid xs={12} sm={5} md={4}>
          <FormControl fullWidth >
              <InputLabel id="select-citta-label">Seleziona Città</InputLabel>
              <Select
              style={{padding:'4px 4px'}}
                value={selectedCitta}
                onChange={(e) => setSelectedCitta(e.target.value)}
                disabled={!selectedRegione} // Disabilita il selettore città finché la regione non è selezionata
                labelId="citta-select-label"
                label={"Seleziona Città"}
              >
                {citta.map((city) => (
                  <MenuItem key={city} value={city}>
                    {city}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid size={8}>
        <Grid xs={12} sm={5} md={4}>
          <FormControl fullWidth >
              <InputLabel id="ufficio postale">Ufficio</InputLabel>
              <Select
                style={{padding:'4px 4px'}}
                value={selectedUfficio}
                onChange={(e) => setSelectedUfficio(e.target.value)}
                disabled={!selectedCitta} // Disabilita il selettore ufficio finché la città non è selezionata
                labelId="ufficio-select-label"
                label={"Seleziona Ufficio postale"}
              >
                {ufficio.map((g) => (
                  <MenuItem key={g} value={g}>
                    {g}
                  </MenuItem>
                ))}
              </Select>
            </FormControl >
          </Grid>
        </Grid>
        <Grid size={12}>
      <Button
              variant="contained"       
              onClick={handleFilter}
            >
              Filtra Immagini
      </Button>


        </Grid>
      </Grid>

      {noRecordFound ?  
        <h2>
        Nessun record trovato
        </h2> 
        : null
      }
  
      <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
      {images.map((image, index) => (
          <Grid key={image._id} size={{ xs: 2, sm: 4, md: 4 }}>
            
            <Card key={image._id} sx={{ maxWidth: 345 }}>
            <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                  <LocationOnIcon />
                  </Avatar>
                }
                action={
                  <>
          {image.status === "validated" ? 
            <IconButton aria-label="settings" sx={{ color: 'red' }} disabled>
            <CheckIcon sx={{color: 'blue'}} />
          </IconButton>
          : null
          }
          </>
        }
                title={image.location}
                subheader={`MTb:${image.ufficioPt.MTb} - MTh:${image.ufficioPt.MTh}`}
              />
              <CardMedia
                component="img"
                height="140"
                image={`${REACT_APP_API_ENDPOINT}/api/${image.path}`}
                alt="Immagine"
                onClick={()=>handleClick(index)}
              />
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                {image.ufficioPt.denominazione}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                   Utente: {image.username}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Data: {new Date(image.uploadDate).toLocaleString()}
                </Typography>
              </CardContent>
            </Card>
           
          </Grid>
        ))}
      </Grid>
      {!!currentImage && (
        /* @ts-ignore */
        <Lightbox
          mainSrc={`${REACT_APP_API_ENDPOINT}/api/${currentImage.path}`}
          imageTitle={`${currentImage.ufficioPt.denominazione} - MTb:${currentImage.ufficioPt.MTb} - MTh:${currentImage.ufficioPt.MTh} - ${new Date(currentImage.uploadDate).toLocaleDateString()}`}
          mainSrcThumbnail={currentImage.path}
          nextSrc={`${REACT_APP_API_ENDPOINT}/api/${nextImage.path}`}
          nextSrcThumbnail={`${REACT_APP_API_ENDPOINT}/api/${nextImage.path}`}
          prevSrc={`${REACT_APP_API_ENDPOINT}/api/${prevImage.path}`}
          prevSrcThumbnail={`${REACT_APP_API_ENDPOINT}/api/${prevImage.path}`}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
    </Container >
  );
};

export default AdminPanelAffissioni;
