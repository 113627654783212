import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Typography, Box, Card, CardContent, Stack } from '@mui/material';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
const { REACT_APP_API_ENDPOINT } = process.env;

const ForgotPasswordStep2 = ({ onNext }) => {
  const [otp, setOtp] = useState(new Array(6).fill('')); // Stato OTP come array di 6 elementi
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(15 * 60); // 15 minuti in secondi

  // Effetto per il countdown
  useEffect(() => {
    if (timeLeft === 0) {
      navigate('/login'); // Reindirizza alla pagina di login se il tempo scade
    }

    const timer = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timer); // Pulizia del timer
  }, [timeLeft, navigate]);

  // Formatta il tempo rimanente in mm:ss
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  // Gestione del cambio valore per i quadratini OTP
  const handleChange = (value, index) => {
    const updatedOtp = [...otp];
    updatedOtp[index] = value.slice(0, 1); // Accetta solo un carattere
    setOtp(updatedOtp);

    // Passa al prossimo quadratino automaticamente
    if (value && index < 5) {
      const nextInput = document.getElementById(`otp-input-${index + 1}`);
      if (nextInput) nextInput.focus();
    }
  };

  // Gestione incolla
  const handlePaste = (e) => {
    const pastedData = e.clipboardData.getData('text').slice(0, 6); // Limita a 6 caratteri
    const updatedOtp = [...otp];

    for (let i = 0; i < pastedData.length; i++) {
      updatedOtp[i] = pastedData[i];
    }
    setOtp(updatedOtp);

    // Sposta il focus all'ultimo quadratino
    const lastInput = document.getElementById(`otp-input-${Math.min(pastedData.length - 1, 5)}`);
    if (lastInput) lastInput.focus();
  };

  const handleNext = async () => {
    const otpValue = otp.join(''); // Combina i valori in una stringa
    const response = await fetch(`${REACT_APP_API_ENDPOINT}/api/auth/verify-otp`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ otp: otpValue })
    });

    if (response.ok) {
      navigate('/forgot/finalstep', { state: { otp: otpValue } });
    } else {
      alert('OTP non valido');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f5f5f5'
      }}
    >
      <Card variant="outlined" sx={{ maxWidth: 500, padding: 2 }}>
        <CardContent>
          <Stack direction="row" spacing={4} sx={{ alignItems: 'flex-end', justifyContent: 'center' }}>
            <ForwardToInboxIcon fontSize="large" />
          </Stack>
          <Typography variant="h5" gutterBottom textAlign="center">
            Inserisci il codice che ti abbiamo inviato via email
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: 1,
              mt: 2
            }}
            onPaste={handlePaste} // Gestione incolla
          >
            {otp.map((digit, index) => (
              <TextField
                key={index}
                id={`otp-input-${index}`}
                value={digit}
                onChange={(e) => handleChange(e.target.value, index)}
                variant="outlined"
                inputProps={{
                  maxLength: 1,
                  style: { textAlign: 'center', fontSize: '1.5rem', width: '3rem' }
                }}
              />
            ))}
          </Box>
          <Button variant="contained" fullWidth onClick={handleNext} sx={{ mt: 2 }}>
            Verifica
          </Button>
          <Typography variant="subtitle1" textAlign="center" sx={{ color: 'gray', mt: 1 }}>
            Tempo rimanente: {formatTime(timeLeft)}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ForgotPasswordStep2;
