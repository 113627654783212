import React, { useState } from 'react';
import { Box, Card, CardContent, TextField, Button, Typography, MenuItem, Select, FormControl, InputLabel, Container } from '@mui/material';
const { REACT_APP_API_ENDPOINT } = process.env;

const CreateCampaign = ({ onSubmit }) => {

  const [campaignType, setCampaignType] = useState('');
  const [denominazione, setDenominazione] = useState('');
  const [descrizione, setDescrizione] = useState('');
  //const [active, setActive] = useState(true); lo imposto nel backend
  const [anno, setAnno] = useState(2025);

  const handleSubmit = async () => {
    if (!denominazione || !campaignType) {
      alert('Per favore, completa tutti i campi.');
      return;
    }

    // Callback per inviare i dati al backend o al componente genitore
    
        try {
                const response = await fetch(`${REACT_APP_API_ENDPOINT}/api/creacampagna/create`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ anno, campaignType, denominazione, descrizione })
            });
            if (response.ok) {
                alert('Campagna creata con successo');
            } else {
                alert('Errore nell\'inserimento');
                
            }
            
            } 
        catch (error) {
            console.error('Errore durante la richiesta di creazione:', error);
            alert('Si è verificato un errore, riprova più tardi.');
            
        }
    
    

    // Resetta i campi dopo l'invio
    setCampaignType('');
    setDenominazione('');
    setDescrizione('');
  };

  return (
    <Container>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        
      }}
    >
      <Card variant="outlined" sx={{ maxWidth: 500, padding: 2 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom textAlign="center">
            Crea una Nuova Campagna
          </Typography>
          <TextField
            fullWidth
            label="Anno es: 2028"
            margin="normal"
            value={anno}
            onChange={(e) => setAnno(parseInt(e.target.value))}
            required
          />
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel id="campaign-type-label">Tipo di Campagna</InputLabel>
            <Select
              labelId="campaign-type-label"
              value={campaignType}
              onChange={(e) => setCampaignType(e.target.value)}
              label="Tipo di Campagna"
              required
            >
              <MenuItem value="Depositi">Depositi</MenuItem>
              <MenuItem value="Uffici">Uffici</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Nome della Campagna"
            margin="normal"
            value={denominazione}
            onChange={(e) => setDenominazione(e.target.value)}
            required
          />
          <TextField
            fullWidth
            label="Descrizione Campagna"
            margin="normal"
            value={descrizione}
            onChange={(e) => setDescrizione(e.target.value)}
            required
          />
          <Button
            variant="contained"
            fullWidth
            onClick={handleSubmit}
            sx={{ mt: 2 }}
          >
            Crea Campagna
          </Button>
        </CardContent>
      </Card>
    </Box>
    </Container>
  );
};

export default CreateCampaign;
