import React, { useEffect, useState } from "react";
import UserInfoCard from "./UserInfoCard"; // Assumi che sia nello stesso folder
//import { Link } from 'react-router-dom';
import Link from '@mui/material/Link';
import axios from "axios";
import { jwtDecode } from "jwt-decode"; // Assicurati di importare correttamente
import {
    Typography
  } from "@mui/material";
const { REACT_APP_API_ENDPOINT } = process.env;

const UserPage = () => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);


  useEffect(() => {
    const fetchUser = async () => {
      try {
        const token = localStorage.getItem('token');
        const decoded = jwtDecode(token);
        const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/users/getOne?id=${decoded.userId}`);
        setUser(response.data);
      } catch (err) {
        setError("Errore nel caricamento dei dati utente");
      }
    };
    fetchUser();
  }, []);

  return (
    <div style={{ padding: "16px" }}>
      {error ? (
        <Typography variant="h6" color="error" align="center">
          {error}
        </Typography>
      ) : (
        <UserInfoCard user={user} />
      )}
      <Typography variant="h6"  align="center">
      Vuoi cambiare password? <Link href="/forgot" underline="hover">
      {'Clicca qui!'}
    </Link>
      
      

    </Typography>
    </div>
  );
};

export default UserPage;
