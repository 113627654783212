import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from './hooks/useAuth'; // Assicurati che questo sia corretto
import Login from './pages/Login';
import Home from './pages/Home';
import Upload from './pages/Upload';
import Gallery from './pages/Gallery';
import UserImages from './pages/UserImages'

import { CircularProgress } from '@mui/material';
import CampaignDepositiSelection from './Campaign/CampaignDepositiSelection';
import CarSearchAndUpdate from './pages/CarSearchAndUpdate';
import ManagedCampaignDashboard from './pages/Managed/ManagedCampaignDashboard';
import Garages from './pages/Garages';
import ParcoAuto from './pages/ParcoAuto';
import CampaignAffissioniSelection from './Campaign/CampaignAffissioniSelection';
import UfficiSearchAndUpdate from './pages/UfficiSearchAndUpdate';
import ManagedCampaingnAffissioniDash from './pages/Managed/ManagedCampaingnAffissioniDash';
import AdminPanelAffissioni from './pages/Admin/AdminPanelAffissioni';
import RegistrationForm from './pages/RegistrationForm';
import Uffici from './pages/Uffici';
import AllImages from './pages/AllImages';
import ManagedPanelDepositi from './pages/Managed/Depositi/ManagedPanelDepositi';
//import ManagedStatDepositi from './pages/Managed/Stat/ManagedStatDepositi';
import ManagedPanelAffissioni from './pages/Managed/Affissioni/ManagedPanelAffissioni';
//import ManagedStatUffici from './pages/Managed/Stat/ManagedStatUffici';
import ManagedUsers from './UsersManaged/ManagedUsers';

import MappX from './mapGoogle/Mapp';
import AdminManagedStatDepositi from './pages/Admin/Stat/AdminManagedStatDepositi';
import AdminManagedStatUffici from './pages/Admin/Stat/AdminManagedStatUffici';
import AdminPanelDepositi from './pages/Admin/AdminPanelDepositi';
import ForgotPasswordStep1 from './pages/ForgotPasswordStep1';
import ForgotPasswordStep2 from './pages/ForgotPasswordStep2';
import ForgotPasswordStep3 from './pages/ForgotPasswordStep3';
import CreateCampaign from './Campaign/CreateCampaign';
import UserPage from './pages/Menu/UserMenuAct/UserPage';


const App = ({ toggleTheme, mode }) => {
  const { isAuthenticated, loading } = useAuth(); // Questo deve recuperare il contesto
  console.log(isAuthenticated);
  if (loading) {
    return <CircularProgress />; // Mostra un indicatore di caricamento finché non carica
  }

  const PrivateRoute = ({ children }) => {
    const { isAuthenticated, loading } = useAuth();
  
    if (loading) {
      return <div>Caricamento...</div>; // Mostra un caricamento mentre verifica il token
    }
  
    return isAuthenticated ? children : <Navigate to="/login" />;
  };

  return (
    <>
      <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/forgot" element={<ForgotPasswordStep1 />} />
      <Route path="/forgot/step2" element={<ForgotPasswordStep2 />} />
      <Route path="/forgot/finalstep" element={<ForgotPasswordStep3 />} />
      <Route
        path="/home"
        element={isAuthenticated ? <Home /> : <Navigate to="/login" />}
      >
      <Route path="mappa" element={<MappX />} />
      <Route path="campain" element={<CampaignDepositiSelection />} />
      <Route path="campain-affissioni" element={<CampaignAffissioniSelection />} />
        <Route path="upload" element={<Upload />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="images" element={<UserImages />} />
        <Route path='automobile' element={<CarSearchAndUpdate />} />
        <Route path='uffici' element={<UfficiSearchAndUpdate />} />
        <Route path='uffici-all' element={<Uffici />} />
        <Route path='garage' element={<Garages />} />
        <Route path='flotta' element={<ParcoAuto />} />
        <Route path='manage/depositi' element={<ManagedPanelDepositi />} />
        <Route path='manage/uffici' element={<ManagedPanelAffissioni />} />
        <Route path='manage/users' element={<ManagedUsers />} />
        <Route path='manage/createcampaign' element={<CreateCampaign />} />

        <Route path="manage/dash-depositi" element={<ManagedCampaignDashboard />} />
        <Route path="manage/dash-uffici" element={<ManagedCampaingnAffissioniDash />} />

        <Route path="manage/registation" element={<RegistrationForm />} />
        <Route path="manage/all-images" element={<AllImages />} />

        <Route path="admin/panel-depositi" element={<AdminPanelDepositi />} />
        <Route path="admin/dash-dep" element={<AdminManagedStatDepositi />} />
        <Route path="admin/dash-uff" element={<AdminManagedStatUffici />} />
        <Route path="admin/panel-affissioni" element={<AdminPanelAffissioni />} />

        <Route path="userinfo" element={<UserPage />} />

        
       {/* <Route path="admin/tutte" element={<AdminTutte />} />
        <Route path="admin/imagesdetail" element={<AdminImagesDetails />} /> */}
      </Route>
      <Route path="*" element={<Navigate to={isAuthenticated ? "/home" : "/login"} />} />
    </Routes>
    </>
  );
};

export default App;
