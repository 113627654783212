import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  Box
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const UserInfoCard = ({ user }) => {
  if (!user) {
    return (
      <Typography variant="h6" color="error" align="center">
        Nessun dato utente disponibile
      </Typography>
    );
  }

  return (
    <Card sx={{ maxWidth: 600, margin: "auto", mt: 4, p: 2 }}>
      <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
        <Avatar sx={{ bgcolor: "primary.main", width: 64, height: 64 }}>
          <AccountCircleIcon fontSize="large" />
        </Avatar>
      </Box>
      <CardContent>
        <Typography variant="h5" align="center" gutterBottom>
          {`${user.nome} ${user.cognome}`}
        </Typography>
        <Grid container spacing={2}>
          <Grid  xs={12}>
            <Typography variant="subtitle1" color="text.secondary">
              <strong>Email:</strong> {user.email}
            </Typography>
          </Grid>
          <Grid  xs={12}>
            <Typography variant="subtitle1" color="text.secondary">
              <strong>Username:</strong> {user.username}
            </Typography>
          </Grid>
          <Grid  xs={12}>
            <Typography variant="subtitle1" color="text.secondary">
              <strong>Ruolo:</strong> {user.role}
            </Typography>
          </Grid>
          <Grid  xs={12}>
            <Typography variant="subtitle1" color="text.secondary">
              <strong>Creato il:</strong>{" "}
              {console.log(JSON.stringify(user))}
              {new Date(user.createdAt).toLocaleDateString("it-IT", {
                year: "numeric",
                month: "long",
                day: "numeric"
              })}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default UserInfoCard;
