import { lazy, Suspense, useMemo, useState } from 'react';
import { MaterialReactTable, useMaterialReactTable, } from 'material-react-table';
import {  Tooltip } from '@mui/material';
import RegistrationModal from './RegistrationModal';
import { useTheme } from '@mui/material/styles';
//import RegistrationNewForm from './RegistrationNewForm';
//import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton } from '@mui/material';

import axios from 'axios';

const { REACT_APP_API_ENDPOINT } = process.env;

const Users = () => {
  const queryClient = useQueryClient();
  const rouli = ['admin', 'manager', 'user'];
  const [isRegistrationOpen, setIsRegistrationOpen] = useState(false);
  // Fetch users data
// Fetch users data
const { data: users = [], isLoading: isLoadingUsers, isError: isLoadingUsersError, isFetching: isFetchingUsers } = useQuery({
  queryKey: ['users'],
  queryFn: async () => {
    const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/users/getall`);
    return response.data.data.map((user) => ({
      ...user,
      active: !!user.active, // Assicura che sia booleano
    }));
  },
});

  // Mutation for updating the active status
  const updateActiveStatus = useMutation({
    mutationFn: async ({ _id, active }) => {
      return await axios.put(`${REACT_APP_API_ENDPOINT}/api/users/updateOne`, {
        _id,
        active,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['users']); // Ricarica i dati
    },
    onError: (error) => {
      console.error('Errore durante l\'aggiornamento:', error);
    },
  });

// Create user
const createUser = useMutation({
  mutationFn: async (newUser) => {
    return await axios.post(`${REACT_APP_API_ENDPOINT}/api/users/create`, newUser);
  },
  onSuccess: () => {
    queryClient.invalidateQueries(['users']); // Ricarica i dati
  },
  onError: (error) => {
    console.error('Errore durante la creazione del record:', error);
  },
});


// Update user
const updateUser = useMutation({
  mutationFn: async (updatedUser) => {
    return await axios.put(`${REACT_APP_API_ENDPOINT}/api/users/updateOne`, updatedUser);
  },
  onSuccess: () => {
    queryClient.invalidateQueries(['users']); // Ricarica i dati
  },
});

const deleteUser = useMutation({
  mutationFn: async (userId) => {
    return await axios.delete(`${REACT_APP_API_ENDPOINT}/api/users/deleteOne`, {
      data: {
        _id: userId,
      },
    });
  },
  onSuccess: () => {
    console.log('Invalidating users query...');
    queryClient.invalidateQueries(['users']); // Forza il refetch dei dati
  },
});


// Crea un nuovo utente
const handleCreateUser = async ({ values, table }) => {
  // Configura i dati della nuova riga
  const newUser = {
    ...values,
    active: values.active || false, // Imposta un valore di default
    role: values.role || 'user', // Imposta un valore di default per il ruolo
  };

  try {
    // Invia la nuova riga al backend
    await createUser.mutateAsync(newUser);

    // Esci dalla modalità di creazione
    table.setCreatingRow(null);
  } catch (error) {
    console.error('Errore durante la creazione del record:', error);
  }
};


// Salva un utente modificato
const handleSaveUser = async ({ row, values, table }) => {
  // Combina i valori originali con i valori modificati
  const updatedUser = {
    ...row.original,
    ...values,
  };

  try {
    // Invia i dati aggiornati al backend
    await updateUser.mutateAsync(updatedUser);

    // Esci dalla modalità editing
    table.setEditingRow(null);
  } catch (error) {
    console.error('Errore durante il salvataggio:', error);
  }
};

const handleUserCreated = () => {
  queryClient.invalidateQueries(['users']); // Ricarica i dati
};

// Elimina un utente
const openDeleteConfirmModal = (row) => {
  if (window.confirm('Sei sicuro di voler eliminare questo utente?')) {
    deleteUser.mutate(row.original._id); // Elimina l'utente
  }
};

  

  // Define columns
  const columns = useMemo(
    () => [
      {
        accessorKey: '_id',
        header: 'ID',
        enableEditing: false,
      },
      {
        accessorKey: 'username',
        header: 'UserName',
      },
      {
        accessorKey: 'nome',
        header: 'Nome',
      },
      {
        accessorKey: 'cognome',
        header: 'Cognome',
      },
      {
        accessorKey: 'email',
        header: 'Email',
      },
      {
        accessorKey: 'password',
        header: 'Password',
        muiEditTextFieldProps: {
          type: 'password',
          required: false,

        },
      },
      /* {
        accessorKey: 'role',
        header: 'Ruolo',
        size: 100,
        editVariant: 'select', // Specifica che questo campo è una select
        editSelectOptions: rouli.map((role) => ({ label: role, value: role })), // Converte 'rouli' in array di opzioni
        muiEditTextFieldProps: {
          select: true, // Necessario per abilitare il menu a tendina
          inputProps: { 'aria-label': 'Seleziona il ruolo' }, // Accessibilità
        },
        Cell: ({ cell }) => (
          <Tooltip title={`Ruolo`}>
            <span>{cell.getValue()}</span> 
          </Tooltip>
        ),
      },
 */

      {
        accessorKey: 'role',
        header: 'Ruolo',
        size: 100,
        Cell: ({ cell }) => {
          const theme = useTheme(); // Accedi al tema corrente
          const value = cell.getValue();
      
          // Mappa dei colori in formato RGB per ogni ruolo
          const roleColors = {
            light: {
              admin: 'rgb(25, 119, 210)', // Blu più scuro
              manager: 'rgb(117, 10, 232)', // Viola scuro
              user: 'rgb(0, 155, 7)', // Verde più scuro

            },
            dark: {
              admin: 'rgb(4, 214, 253)', // Blu brillante
              manager: 'rgb(237, 234, 145)', // Giallo
              user: 'rgb(36, 231, 44)', // Verde
            },
          };
      
          // Determina i colori in base al tema attuale
          const currentThemeColors = theme.palette.mode === 'dark' ? roleColors.dark : roleColors.light;
      
          return (
            <span
              style={{
                color: currentThemeColors[value] || (theme.palette.mode === 'dark' ? 'white' : 'black'),
                fontWeight: 'bold',
              }}
            >
              {value.charAt(0).toUpperCase() + value.slice(1)}
            </span>
          );
        },
        editVariant: 'select',
        editSelectOptions: [
          { label: 'Admin', value: 'admin' },
          { label: 'Manager', value: 'manager' },
          { label: 'User', value: 'user' },
        ],
        muiEditTextFieldProps: {
          select: true,
        },
      },
      
      /* {
        accessorKey: 'active',
        header: 'Attivo',
        size: 100,
        editVariant: 'select', // Specifica che questo campo è una select
        editSelectOptions: [
          { label: 'Attivo', value: true },
          { label: 'Non Attivo', value: false },
        ], // Le opzioni per la select
        muiEditTextFieldProps: {
          select: true, // Necessario per abilitare il menu a tendina
        },
        Cell: ({ cell }) => (
         <><Tooltip title={cell.getValue() ? 'Attivo' : 'Non Attivo'}>
            {cell.getValue() ? 'Attivo' : 'Non Attivo'}
          </Tooltip></> 
        ),
      }, */
      {
        accessorKey: 'active',
        header: 'Stato',
        size: 100,
        Cell: ({ cell }) => {
          const value = cell.getValue(); // Ottieni il valore (true/false)
          return (
            <span
              style={{
                color: value ? '#04ac04' : '#ff4500', // Verde per "Attivo", ArancioRosso per "Non Attivo"
                fontWeight: 'bold', // Grassetto per evidenziare
              }}
            >
              {value ? 'Attivo' : 'Non Attivo'}
            </span>
          );
        },
        muiEditTextFieldProps: {
          select: true,
        },
        editVariant: 'select',
        editSelectOptions: [
          { label: 'Attivo', value: true },
          { label: 'Non Attivo', value: false },
        ],
      }

    ],
    [updateActiveStatus]
  );

  const table = useMaterialReactTable({
    columns,
    data: users,
    createDisplayMode: 'row',
    editDisplayMode: 'row',
    enableEditing: true,
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: true,
      columnVisibility: { password: false }
    },
    getRowId: (row) => row._id,
    localization: {actions: 'Azioni',
      and: 'e',
      cancel: 'Cancella',
      changeFilterMode: 'Cambia modalità filtro',
      changeSearchMode: 'Cambia modalità ricerca',
      clearFilter: 'Cancella filtro',
      clearSearch: 'Cancella ricerca',
      clearSelection: 'Cancella selezione',
      clearSort: 'Cancella ordinamento',
      clickToCopy: 'Click per copiare',
      copy: 'Copia',
      collapse: 'Chiudi',
      collapseAll: 'Chiudi tutto',
      columnActions: 'Azioni colonna',
      copiedToClipboard: 'Copia negli appunti',
      dropToGroupBy: 'Crea gruppo da {column}',
      edit: 'Modifica',
      expand: 'Espandi',
      expandAll: 'Espandi tutto',
      filterArrIncludes: 'Includi',
      filterArrIncludesAll: 'Includi tutto',
      filterArrIncludesSome: 'Inclusioni',
      filterBetween: 'Tra',
      filterBetweenInclusive: 'Tra inclusioni',
      filterByColumn: 'Filtra da {column}',
      filterContains: 'Contiene',
      filterEmpty: 'Vuoto',
      filterEndsWith: 'Finisce con',
      filterEquals: 'Uguale',
      filterEqualsString: 'Uguale',
      filterFuzzy: 'Sfocato',
      filterGreaterThan: 'Maggiore di',
      filterGreaterThanOrEqualTo: 'Maggiore o uguale a',
      filterInNumberRange: 'Tra',
      filterIncludesString: 'Contiene',
      filterIncludesStringSensitive: 'Contiene',
      filterLessThan: 'Minore di',
      filterLessThanOrEqualTo: 'Minore o uguale a',
      filterMode: 'Modalità filtro: {filterType}',
      filterNotEmpty: 'Non vuoto',
      filterNotEquals: 'Non uguale',
      filterStartsWith: 'Inizia con',
      filterWeakEquals: 'Uguale',
      filteringByColumn: 'Filtrando da {column} - {filterType} {filterValue}',
      goToFirstPage: 'Vai alla prima pagina',
      goToLastPage: "Vai all'ultima pagina",
      goToNextPage: 'Vai alla pagina successiva',
      goToPreviousPage: 'Vai alla pagina precedente',
      grab: 'Prendi',
      groupByColumn: 'Raggruppa da {column}',
      groupedBy: 'Raggruppa da ',
      hideAll: 'Nascondi tutto',
      hideColumn: 'Nascondi la colonna {column}',
      max: 'Massimo',
      min: 'Minimo',
      move: 'Sposta',
      noRecordsToDisplay: 'Nessun record da mostrare',
      noResultsFound: 'Nessun risultato trovato',
      of: 'di',
      or: 'o',
      pin: 'Blocca',
      pinToLeft: 'Blocca a sinistra',
      pinToRight: 'Blocca a destra',
      resetColumnSize: 'Resetta dimensione colonna',
      resetOrder: 'Resetta ordine',
      rowActions: 'Azioni riga',
      rowNumber: '#',
      rowNumbers: 'Righe',
      rowsPerPage: 'Righe per pagina',
      save: 'Salva',
      search: 'Cerca',
      selectedCountOfRowCountRowsSelected:
        '{selectedCount} di {rowCount} righe selezionate',
      select: 'Seleziona',
      showAll: 'Mostra tutto',
      showAllColumns: 'Mostra tutte le colonne',
      showHideColumns: 'Mostra/Nascondi colonne',
      showHideFilters: 'Mostra/Nascondi filtri',
      showHideSearch: 'Mostra/Nascondi ricerca',
      sortByColumnAsc: 'Ordina da {column} crescente',
      sortByColumnDesc: 'Ordina da {column} decrescente',
      sortedByColumnAsc: 'Ordinato da {column} crescente',
      sortedByColumnDesc: 'Ordinato da {column} decrescente',
      thenBy: ', poi da',
      toggleDensity: 'Cambia densità',
      toggleFullScreen: 'Cambia schermo intero',
      toggleSelectAll: 'Cambia selezione totale',
      toggleSelectRow: 'Cambia selezione riga',
      toggleVisibility: 'Cambia visibilità',
      ungroupByColumn: 'Rimuovi gruppo {column}',
      unpin: 'Sblocca',
      unpinAll: 'Sblocca tutto',
    },
    muiToolbarAlertBannerProps: isLoadingUsersError
      ? {
          color: 'error',
          children: 'Errore durante il caricamento degli utenti.',
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: '500px',
      },
    },
    state: {
      isLoading: isLoadingUsers,
      isSaving: createUser.isLoading || updateUser.isLoading || deleteUser.isLoading,
      showAlertBanner: isLoadingUsersError,
      showProgressBars: isFetchingUsers,
    },
    onCreatingRowSave: handleCreateUser, // Configura il salvataggio della nuova riga
    onEditingRowSave: (props) => handleSaveUser(props), // Passa `props` a `handleSaveUser`
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <button
        onClick={() => setIsRegistrationOpen(true)} // Mostra il componente di registrazione
        style={{
          backgroundColor: '#1976d2',
          color: '#fff',
          padding: '0.5rem 1rem',
          border: 'none',
          cursor: 'pointer',
        }}
      >
        + Crea Utente
      </button>
    ),
  });

  
  

  return (
    <>
    <MaterialReactTable
      table={table}
    />
    {isRegistrationOpen && (
      <RegistrationModal
        open={isRegistrationOpen}
        onClose={() => setIsRegistrationOpen(false)}
        onUserCreated={handleUserCreated}
      />
    )}
  </>
  );
};

//react query setup in App.tsx
const ReactQueryDevtoolsProduction = lazy(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then(
    (d) => ({
      default: d.ReactQueryDevtools,
    }),
  ),
);

const queryClient = new QueryClient();
export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Users />
      <Suspense fallback={null}>
        <ReactQueryDevtoolsProduction />
      </Suspense>
    </QueryClientProvider>
  );
}

//export default App;


//export default AppUsers;
