import '../login.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import axios from 'axios';
import { TextField, Button, Container, Typography, Box, CssBaseline, FormControl, InputLabel } from '@mui/material';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid2';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import HttpsIcon from '@mui/icons-material/Https';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import BACKGROUNDIMG from '../WhatsAppImage.jpg';
import { useTheme } from '@emotion/react';
const { REACT_APP_API_ENDPOINT } = process.env;

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
      Sinergica srl
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
const theme = createTheme();
const useStyles = makeStyles({
  root: {
    height: "100vh",
    backgroundImage: `url(${BACKGROUNDIMG})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],

    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  size: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },

  paper: {
    margin: theme.spacing(2, 6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
});

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();
  const classes = useStyles();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Qui dovresti fare la chiamata al backend per ottenere il token
    axios.post(`${REACT_APP_API_ENDPOINT}/api/users/login`, {username,
      password,
    }).then((response) => {
      console.log(response.status, response.data.token);
      const token = response.data.token; // Simula il token del backend
    login(token); // Salva il token e aggiorna lo stato autenticato
    navigate('/home'); // Reindirizza l'utente alla home
    }).catch((error) => {
      setErrorMessage(error.response.data.message)
      console.log(error);
    });
    
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      {/* <Grid item={true} xs={false} sm={4} md={7} className={classes.image} /> */}
      <Grid
        className={classes.size}
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={1}
        square
      >
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <HttpsIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <FormControl fullWidth >
          <InputLabel id="username_label"></InputLabel>
            <TextField
            onChange={(e) => setUsername(e.target.value)}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              value={username}
              autoFocus
            />
            </FormControl>
            <FormControl fullWidth >
          <InputLabel id="password_label"></InputLabel>
            <TextField
            onChange={(e) => setPassword(e.target.value)}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={password}
              autoComplete="current-password"
            />
            </FormControl>
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Ricordami"
            />
            {errorMessage && (
          <Box sx={{ width: '100%', maxWidth: 500 }}>
          <Typography variant="h6" gutterBottom sx={{color: 'red'}}>
            {errorMessage}
          </Typography>
          </Box>
        )}
            <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Sign In
        </Button>
            <Grid container>
              <Grid >
                <Link href="/forgot" variant="body2">
                  {"Password dimenticata?"}
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
};

export default Login;
