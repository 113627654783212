import React, { useEffect, useState } from 'react';
import { Box, Typography, CircularProgress, Container, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import axios from 'axios';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Stack from '@mui/material/Stack';

const ManagedCampaignDashboard = () => {
  const [campaignMenu, setCampaignMenu] = useState([]);
  const [regioni, setRegioni] = useState([]);
  const [citta, setCitta] = useState([]);
  const [deposito, setDeposito] = useState([]);
  const [campaignSelected, setCampaignSelected] = useState('');
  const [campaignData, setCampaignData] = useState([]);
  const [campaignOveralData, setCampaignOveralData] = useState({});
  const [selectedRegione, setSelectedRegione] = useState('');
  const [selectedCitta, setSelectedCitta] = useState('');
  const [selectedGarage, setSelectedGarage] = useState('');

  const [loading, setLoading] = useState(false);
  const [errorFetch, setErrorFetch] = useState('')

  const { REACT_APP_API_ENDPOINT } = process.env;

  const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));

useEffect(() => {
    const fetchCampaignProgress = async () => {
      try {
        const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/campaigns-menu`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        console.log('Campaign:', response.data);
        setCampaignMenu(response.data);
      } catch (error) {
        console.error('Error fetching campaign progress:', error);
      }
    };
    
    // Usa l'ID della campagna che vuoi testare
    fetchCampaignProgress('66f852d3bf7b41110a6d0323'); // Assicurati che questo ID esista nel database
  }, []);

  // Per recuperare le regioni disponibili dal backend
  useEffect(() => {
    const fetchRegioni = async () => {
      try {
        const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/regioni`);
        setRegioni(response.data);
      } catch (error) {
        console.error('Errore nel recupero delle regioni:', error);
      }
    };

    fetchRegioni();
  }, []);

    // Quando una regione è selezionata, recupera le città corrispondenti
    useEffect(() => {
      if (selectedRegione) {
        const fetchCitta = async () => {
          try {
            const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/citta?regione=${selectedRegione}`);
            setCitta(response.data);
          } catch (error) {
            console.error('Errore nel recupero delle città:', error);
          }
        };
  
        fetchCitta();
      }
    }, [selectedRegione]);

      // Quando una città è selezionata, recupera i garage corrispondenti

      
  useEffect(() => {
    if (selectedCitta) {
      const fetchGarage = async () => {
        try {
          const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/indirizzo?citta=${selectedCitta}`);
          setDeposito(response.data);
        } catch (error) {
          console.error('Errore nel recupero dei depositi:', error);
        }
      };

      fetchGarage();
    }
  }, [selectedCitta]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  function chartSetting(labels, media){

      return  {
    yAxis: [
      {
        label: `Completato ${media}%`,
      },
      
    ],
    width: 400,
    height: 300,
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: 'translate(-8px, 10)',
      },
    },
  };
  }
  const colors = [
  '#02b2af',
  '#72ccff',
  '#da00ff',
  '#FF33A1',
  '#FF8F33',
  '#33FFF5',
  '#B833FF',
];


const fetchCampaignOveralProgress = async (campaignId) => {
      try {
        const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/campaigns/${campaignId}/overall-progress`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        console.log('Campaign Progress:', response.data);
        setCampaignOveralData(response.data);
      } catch (error) {
        console.error('Error fetching campaign progress:', error);
      }
}

const buildQueryString = (params) => {
  return Object.entries(params)
    .filter(([_, value]) => value !== undefined && value !== null) // Filtra i parametri null o undefined
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`) // Codifica i parametri
    .join('&');
};



const fetchCampaignProgress = async () => {

  const params = {
    regione: selectedRegione || undefined,
    localita: selectedCitta || undefined
  };
  
  // Costruisce l'URL con i parametri di query
  const queryString = buildQueryString(params);
  const endpoint = `${REACT_APP_API_ENDPOINT}/api/campaigns/${campaignSelected}/progress${queryString ? `?${queryString}` : ''}`;

      try {
        const response = await axios.get(endpoint, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        console.log('Campaign Progress:', response.data);
        setCampaignData(response.data);
        setErrorFetch('')
      } catch (error) {
        setCampaignData([]);
        setErrorFetch(error.response.data.message)
        console.error('Error fetching campaign progress:', error);
      }
}

const handleFilter = (event) => {
/*   console.log(campaignMenu);
  setCampaignSelected(event.target.value);
  fetchCampaignOveralProgress(event.target.value)*/
  fetchCampaignProgress() 
  }; 

const MenuCampagne = () => {

  return (

    <>


<Grid container spacing={2} style={{marginBottom: '25px'}}>

     <Grid size={6}>
       <Grid xs={12} sm={5} md={4}>
       <FormControl fullWidth>
       <InputLabel id="campagna-label">Seleziona Campagna</InputLabel>
       <Select
         labelId="campagna-select-label"
         id="campagna-select-label"
         label="Seleziona Campagna"
       //  onChange={handleChange}
         value={campaignSelected}
         onChange={(e) => setCampaignSelected(e.target.value)}
       >
         {campaignMenu?.map((option, index) => {
           return (
             <MenuItem key={option._id} value={option._id}>
               {option.denominazione} - {option.anno}
             </MenuItem>
           )
         })}
 
   </Select>
 </FormControl>
       </Grid>
     </Grid>

     <Grid size={6}>
     <Grid xs={12} sm={5} md={4}>
       <FormControl fullWidth >
           <InputLabel id="regione-label">Seleziona Regione</InputLabel>
           <Select
           style={{padding:'4px 4px'}}
             value={selectedRegione}
             onChange={(e) => setSelectedRegione(e.target.value)}
             labelId="regione-select-label"
             label={"Seleziona Regione"}
           >
             {regioni.map((regione) => (
               <MenuItem key={regione} value={regione}>
                 {regione}
               </MenuItem>
             ))}
             <MenuItem value={''} onChange={(e) => setSelectedRegione('')} style={{color:'#058e08', fontWeight:'bolder'}}>NULL</MenuItem>
           </Select>
         </FormControl>
       </Grid>

     </Grid>

     <Grid size={6}>
     <Grid xs={12} sm={5} md={4}>
       <FormControl fullWidth >
           <InputLabel id="citta-label">Seleziona Città</InputLabel>
           <Select
           style={{padding:'4px 4px'}}
             value={selectedCitta}
             onChange={(e) => setSelectedCitta(e.target.value)}
             disabled={!selectedRegione} // Disabilita il selettore città finché la regione non è selezionata
             labelId="citta-select-label"
             label={"Seleziona città"}
             >
             {citta.map((city) => (
               <MenuItem key={city} value={city}>
                 {city}
               </MenuItem>
             ))}
             <MenuItem value={''} onChange={(e) => setSelectedCitta('')} style={{color:'#058e08', fontWeight:'bolder'}}>NULL</MenuItem>
           </Select>
         </FormControl>
       </Grid>
     </Grid>

     <Grid size={6}>
     <Grid xs={12} sm={5} md={4}>
       <FormControl fullWidth >
           <InputLabel id="garage-label">Deposito</InputLabel>
           <Select
            style={{padding:'4px 4px'}}
            labelId="garage-select-label"
            label={"Garage"}
             value={selectedGarage}
             onChange={(e) => setSelectedGarage(e.target.value)}
             disabled={!selectedCitta} // Disabilita il selettore garage finché la città non è selezionata
           >
             {deposito.map((g) => (
               <MenuItem key={g} value={g}>
                 {g}
               </MenuItem>
             ))}
             <MenuItem value={''} onChange={(e) => setSelectedGarage('')} style={{color:'#058e08', fontWeight:'bolder'}}>NULL</MenuItem>
           </Select>
         </FormControl >
       </Grid>
     </Grid>
     <Grid size={4}>
   <Button
           variant="contained"
           
           onClick={handleFilter}
         >
           Filtra depositi
         </Button>
     </Grid>

   </Grid>
</>
  )
}
//  [totalCar, completedCars, inProgressCars, completionRate] = campaignOveralData;

const isEmptyObject = (obj) => JSON.stringify(obj) === '{}';


  return (
    <Container>
    <Box sx={{ padding: 4 }}>
    <Stack alignItems="center" direction="row" gap={2} sx={{ mb:4 }}>
      <LocalShippingIcon />
      <Typography variant="body1"> Dashboard progresso campagna depositi</Typography>
    </Stack>
        
    {MenuCampagne()}
    {errorFetch}

    {isEmptyObject({campaignOveralData}) ? null :
    <Typography variant="h6" gutterBottom sx={{ mt:4 }}>
       Percentuale completata: {campaignOveralData.completionRate} % -  Totali: {campaignOveralData.totalCars} - Completati {campaignOveralData.completedCars} - Progress {campaignOveralData.inProgressCars}
      </Typography>
      
    }
    </Box>
    <div style={{ width: '100%' }}  >
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

      {campaignData.map((progress, index) => (

        <Grid  key={index} size={{ xs: 12, md: 4 }}>
          <Item>

          <BarChart
          //  dataset={progress}
          xAxis={[{ scaleType: 'band', data: ['completed', 'progress', 'n° auto'], colorMap: {
            type: 'ordinal',
            colors,
          },}]}
          //  xAxis={[{ scaleType: 'band', dataKey: "garage" }]}
          series={[{ data: [progress.completed, progress.in_progress, progress.total], label: `${progress.garage}` }]}
          slotProps={{ legend: { hidden: false } }}
          barLabel="value"
          /*
            series={[
              { dataKey: progress.completed, label: 'completed', fill: '#82ca9d' },
              { dataKey: progress.in_progress, label: 'in progress', fill: '#8884d8' },
              { dataKey: progress.total, label: 'tot. mezzi', fill: ' #DAF7A6' }
            ]}
*/
           {...chartSetting(progress.garage, progress.media)}
          />
                
          </Item>
        </Grid>

              ))}


      </Grid>
    </div>
</Container>
  );
};

export default ManagedCampaignDashboard;
