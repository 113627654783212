import React, { useMemo, useCallback, useState, useEffect, useContext } from 'react';
import ControlPanel from './ControlPanel';
import {
  AdvancedMarker,
  APIProvider,
  InfoWindow,
  Map,
  Pin,
} from '@vis.gl/react-google-maps';
import { ThemeContext } from '../context/ThemeContext';

const { REACT_APP_GOOGLE_MAPS_API_KEY } = process.env;
const { REACT_APP_API_ENDPOINT } = process.env;

const MappX = () => {
  const { darkMode } = useContext(ThemeContext);
  const [offices, setOffices] = useState([]);
  const [garages, setGarages] = useState([]);
  const [loadingOffices, setLoadingOffices] = useState(true);
  const [loadingGarages, setLoadingGarages] = useState(true);

  // Stati per il controllo
  const [showOffices, setShowOffices] = useState(true);
  const [showGarages, setShowGarages] = useState(true);
  const [selectedCity, setSelectedCity] = useState('');

  const position = { lat: 41.924598693847656, lng: 12.445199012756348 };
  const style = { width: '100%', height: '90vh' };

  // Fetch dei dati
  useEffect(() => {
    const fetchOffices = async () => {
      try {
        const response = await fetch(`${REACT_APP_API_ENDPOINT}/api/offices/offices_all`);
        const result = await response.json();
        if (Array.isArray(result.data)) setOffices(result.data);
      } catch (error) {
        console.error('Errore di fetch uffici:', error);
      } finally {
        setLoadingOffices(false);
      }
    };

    const fetchGarages = async () => {
      try {
        const response = await fetch(`${REACT_APP_API_ENDPOINT}/api/depositi/garages`);
        const result = await response.json();
        if (Array.isArray(result.data)) setGarages(result.data);
      } catch (error) {
        console.error('Errore di fetch garages:', error);
      } finally {
        setLoadingGarages(false);
      }
    };

    fetchOffices();
    fetchGarages();
  }, []);

  // Filtri
  const filteredOffices = useMemo(() => {
    if (!showOffices) return [];
    if (selectedCity) {
      return offices.filter((office) => office.citta === selectedCity);
    }
    return offices;
  }, [offices, showOffices, selectedCity]);

  const filteredGarages = useMemo(() => {
    if (!showGarages) return [];
    if (selectedCity) {
      return garages.filter((garage) => garage.localita === selectedCity);
    }
    return garages;
  }, [garages, showGarages, selectedCity]);

  // Estrarre tutte le città uniche
  const cities = useMemo(() => {
    const officeCities = offices.map((office) => office.citta);
    const garageCities = garages.map((garage) => garage.localita);
    return Array.from(new Set([...officeCities, ...garageCities]));
  }, [offices, garages]);

  return (
    <div className="App" style={style}>
      <APIProvider apiKey={REACT_APP_GOOGLE_MAPS_API_KEY} libraries={['marker']}>
        <ControlPanel
          showOffices={showOffices}
          setShowOffices={setShowOffices}
          showGarages={showGarages}
          setShowGarages={setShowGarages}
          selectedCity={selectedCity}
          setSelectedCity={setSelectedCity}
          cities={cities}
        />
        <Map
          mapId="67cf8c0012e507fa"
          defaultZoom={6}
          defaultCenter={position}
          gestureHandling={'greedy'}
          clickableIcons={false}
          disableDefaultUI
          colorScheme={darkMode ? 'DARK' : 'LIGHT'}
        >
          {!loadingOffices && <PoiMarkers pois={filteredOffices} title="Ufficio" />}
          {!loadingGarages && <PoiMarkers pois={filteredGarages} title="Deposito" />}
        </Map>
      </APIProvider>
    </div>
  );
};


// Componente Generico per i Marker
const PoiMarkers = ({ pois, title }) => {
  const [selectedInfo, setSelectedInfo] = useState(null);
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const { darkMode } = useContext(ThemeContext); // Accedi alla modalità Dark Mode

  const handleMarkerClick = useCallback((poi) => {
    setSelectedInfo(poi);
    setSelectedPosition({
      lat: parseFloat(poi.lat),
      lng: parseFloat(poi.lng),
    });
    setInfoWindowOpen(true);
  }, []);

  // Stili dinamici per InfoWindow
  const infoWindowStyle = {
    backgroundColor: darkMode ? '#333' : '#fff', // Sfondo scuro per modalità dark
    color: darkMode ? '#fff' : '#000',          // Testo chiaro per modalità dark
    padding: '10px',
    borderRadius: '8px',
    boxShadow: darkMode ? '0 4px 8px rgba(255, 255, 255, 0.2)' : '0 4px 8px rgba(0, 0, 0, 0.2)',
  };


  return (
    <>
      {/* Renderizzazione dei punti */}
      {Array.isArray(pois) && pois.map((poi, index) => {
        const lat = parseFloat(poi.lat);
        const lng = parseFloat(poi.lng);

        if (isNaN(lat) || isNaN(lng)) {
          console.warn('Coordinate non valide:', poi);
          return null;
        }

        return (
          <AdvancedMarker
            key={poi._id?.$oid || index}
            position={{ lat, lng }}
            onClick={() => handleMarkerClick(poi)}
          >
            <Pin
              background={title === 'Deposito' ? '#0033cc' : '#FBBC04'}
              glyphColor={title === 'Deposito' ? '#FBBC04' : '#0033cc'}
              borderColor={title === 'Deposito' ? '#FBBC04' : '#0033cc'}
            />
          </AdvancedMarker>
        );
      })}

      {/* InfoWindow */}
      {infoWindowOpen && selectedPosition && (
        <InfoWindow
          position={selectedPosition}
          maxWidth={367}
          onCloseClick={() => setInfoWindowOpen(false)}
        >
          <div style={infoWindowStyle}>
            <h4>{title === 'Deposito' ? 'Deposito' : 'Ufficio'}</h4>
            <ul style={{ listStyle: 'none', padding: 3, margin: 0 }}>
            {title === 'Deposito' ? (
              <>
                <li style={{ marginBottom: '8px' }}><strong>Regione:</strong> {selectedInfo?.regione}</li>
                <li style={{ marginBottom: '8px' }}><strong>Località:</strong> {selectedInfo?.localita}</li>
                <li style={{ marginBottom: '8px' }}><strong>Indirizzo:</strong> {selectedInfo?.indirizzo}</li>
                <li style={{ marginBottom: '8px' }}><strong>CAP:</strong> {selectedInfo?.CAP}</li>
                <li style={{ marginBottom: '8px' }}><strong>Provincia:</strong> {selectedInfo?.provincia}</li>
                <li style={{ marginBottom: '8px' }}><strong>Recapito:</strong> {selectedInfo?.recapito}</li>
              </>
            ) : (
              <>
                <li style={{ marginBottom: '8px' }}><strong>Regione:</strong> {selectedInfo?.regione}</li>
                <li style={{ marginBottom: '8px' }}><strong>Città:</strong> {selectedInfo?.citta}</li>
                <li style={{ marginBottom: '8px' }}><strong>Denominazione:</strong> {selectedInfo?.denominazione}</li>
                <li style={{ marginBottom: '8px' }}><strong>Indirizzo:</strong> {selectedInfo?.indirizzo}</li>
                {selectedInfo?.materiale && (
                  <li style={{ marginBottom: '8px' }}><strong>Materiale:</strong> {selectedInfo?.materiale}</li>
                )}
              </>
            )}
          </ul>
          </div>
        </InfoWindow>
      )}
    </>
  );
};


export default MappX;
