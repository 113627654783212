import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import BusinessIcon from '@mui/icons-material/Business';
import GarageIcon from '@mui/icons-material/Garage';
import CarRentalIcon from '@mui/icons-material/CarRental';
import ToysIcon from '@mui/icons-material/Toys';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import BarChartIcon from '@mui/icons-material/BarChart';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import CampaignIcon from '@mui/icons-material/Campaign';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

export default function GestioneMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event) => {
    console.log(event.currentTarget)
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event, index) => {
    //  setSelectedIndex(index);
    if (index === 1) {
          navigate(`/home/uffici-all`)
      }
      if (index === 2) {
          navigate('/home/garage/');
      }
      if (index === 3) {
        navigate('/home/flotta');
      }
      if (index === 4) {
      navigate('/home/manage/registation');
      } 
      if (index === 11) {
        navigate(`/home/manage/users`)
      }
      if (index === 5) {
      navigate('/home/manage/all-images');
      }
      if (index === 6) {
      navigate('/home/manage/depositi');
      }
      if (index === 7) {
        navigate(`/home/manage/dash-depositi`)
        } 
      if (index === 8) {
        navigate(`/home/manage/dash-uffici`)
        }
      if (index === 9) {
        navigate(`/home/manage/uffici`)
        }
        if (index === 10) {
        navigate(`/home/manage/createcampaign`)
        } 
      setAnchorEl(null);
    };

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{color: 'inherit'}}
      >
        Gestione
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
      <MenuItem onClick={(event) => handleMenuItemClick(event, 7)}>
      <ListItemIcon>
          <BarChartIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Dash Depositi</ListItemText>

      </MenuItem>

      <MenuItem onClick={(event) => handleMenuItemClick(event, 8)}>
      <ListItemIcon>
          <BarChartIcon  fontSize="small" />
        </ListItemIcon>
        <ListItemText>Dash Affissioni</ListItemText>
      </MenuItem>

      <MenuItem onClick={(event) => handleMenuItemClick(event, 1)}>
      <ListItemIcon>
          <BusinessIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Uffici</ListItemText>

      </MenuItem>

       <MenuItem onClick={(event) => handleMenuItemClick(event, 2)}>
       <ListItemIcon>
           <GarageIcon fontSize="small" />
         </ListItemIcon>
         <ListItemText>Depositi</ListItemText>

       </MenuItem>

       <MenuItem onClick={(event) => handleMenuItemClick(event, 3)}>
       <ListItemIcon>
           <CarRentalIcon  fontSize="small" />
         </ListItemIcon>
         <ListItemText>Flotta</ListItemText>
       </MenuItem>

       <MenuItem onClick={(event) => handleMenuItemClick(event, 11)}>
       <ListItemIcon>
           <GroupAddIcon fontSize="small" />
         </ListItemIcon>
         <ListItemText>Utenti</ListItemText>
       
       </MenuItem> 

       <MenuItem onClick={(event) => handleMenuItemClick(event, 4)}>
       <ListItemIcon>
           <PersonAddIcon fontSize="small" />
         </ListItemIcon>
         <ListItemText>+ Utente</ListItemText>
       
       </MenuItem>

       <MenuItem onClick={(event) => handleMenuItemClick(event, 10)}>
       <ListItemIcon>
           <CampaignIcon fontSize="small" />
         </ListItemIcon>
         <ListItemText>+ Campagna</ListItemText>
       
       </MenuItem> 

       <MenuItem onClick={(event) => handleMenuItemClick(event, 5)}>
       <ListItemIcon>
           <PhotoCameraIcon fontSize="small" />
         </ListItemIcon>
         <ListItemText>Immagini</ListItemText>
       
       </MenuItem> 

       <MenuItem onClick={(event) => handleMenuItemClick(event, 6)}>
       <ListItemIcon>
           <ToysIcon fontSize="small" />
         </ListItemIcon>
         <ListItemText>Manage Depositi</ListItemText>
       </MenuItem> 

       <MenuItem onClick={(event) => handleMenuItemClick(event, 9)}>
       <ListItemIcon>
           <MapsHomeWorkIcon  fontSize="small" />
         </ListItemIcon>
         <ListItemText>Manage Affissioni</ListItemText>
       </MenuItem>

      </Menu>
    </>
  );
}
