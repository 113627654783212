import React, { useState } from 'react';
import { TextField, Button, Typography, Box, CircularProgress, Card, CardContent, Stack  } from '@mui/material';
import KeyIcon from '@mui/icons-material/Key';
import { useNavigate } from 'react-router-dom';
const { REACT_APP_API_ENDPOINT } = process.env;

const ForgotPasswordStep1 = ({ onNext }) => {
  const [emailOrUsername, setEmailOrUsername] = useState('');
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback]= useState('');
  const navigate = useNavigate();
  const handleNext = async () => {
    
  /*   const response = await fetch(`${REACT_APP_API_ENDPOINT}/api/auth/request-reset`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ emailOrUsername })
    }); 

    if (response.ok) {
        navigate('/forgot/step2');
     // onNext(); // Passa allo step successivo
    } else {
      alert('Email o username non trovati');
    }
*/
//
setLoading(true); // Mostra il caricamento
setFeedback('')
    try {
      const response = await fetch(`${REACT_APP_API_ENDPOINT}/api/auth/request-reset`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ emailOrUsername })
      });

      if (response.ok) {
       // onNext(); // Passa allo step successivo
       navigate('/forgot/step2');
      } else {
        alert('Email o username non trovati');
        setFeedback('Email o username non trovati')
      }
    } catch (error) {
      console.error('Errore durante la richiesta di reset:', error);
      alert('Si è verificato un errore, riprova più tardi.');
      setFeedback('Si è verificato un errore, riprova più tardi.')
    } finally {
      setLoading(false); // Nasconde il caricamento
    }




  };

  return (
    <Box
    sx={{
      display: 'flex',           // Attiva il layout Flexbox
      justifyContent: 'center',  // Allinea orizzontalmente
      alignItems: 'center',      // Allinea verticalmente
      height: '100vh',           // Altezza dell'intero viewport
      backgroundColor: '#f5f5f5' // (Opzionale) Sfondo per maggiore contrasto
    }}
  >
    <Card variant="outlined" sx={{ maxWidth: 500, padding: 2 }}>
      <CardContent>
        <Stack direction="row" spacing={4} sx={{ alignItems: 'flex-end', justifyContent: 'center' }}>
          <KeyIcon fontSize="large" />
        </Stack>
        <Typography variant="h5" gutterBottom textAlign="center">
        Recupero Password
        </Typography>
        <TextField
        fullWidth
        label="Email o Username"
        margin="normal"
        value={emailOrUsername}
        onChange={(e) => setEmailOrUsername(e.target.value)}
        disabled={loading} // Disabilita il campo durante il caricamento
      />
      <Box sx={{ mt: 2, position: 'relative' }}>
        <Button
          variant="contained"
          fullWidth
          onClick={handleNext}
          disabled={loading} // Disabilita il bottone durante il caricamento
        >
          Invia
        </Button>
        {loading && (
          <CircularProgress
            size={24}
            sx={{
              color: 'primary.main',
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px'
            }}
          />
        )}
        </Box>
        <Typography variant="h5" gutterBottom style={{color: 'red'}}>{feedback}</Typography>
      </CardContent>
    </Card>
  </Box>
  );
};

export default ForgotPasswordStep1;
